import "./style.scss";
import * as react from "react";

interface Props {
  children?: react.ReactNode;
}

export default function Background(props: Props) {
  return <div className="gradient-bg">{props.children}</div>;
}
