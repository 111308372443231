import "./style.scss";
import LogoWShadow from "../../../assets/logowshadow.png";
interface IProps {
  sponsorLink: string;
  sponsorImage: string;
  sponsorMessage: string;
}

export default function SponsorPopup(props: IProps) {
  const { sponsorLink, sponsorImage, sponsorMessage } = props;
  return (
    <>
      <div className="bg-sponsor">
        <div className="container">
          <div className="title"></div>
          <div className="sponsor-msg">{sponsorMessage}</div>
          <img src={sponsorImage} alt="sponsor" style={{ objectFit: "fill" }} />
        </div>
        <img src={LogoWShadow} alt="logo" className="logo" />
      </div>
    </>
  );
}
