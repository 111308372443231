export function getFormatDescription(format: string) {
  if (format === "sitngo") {
    return "This tournament is a Sit & Go format. The tournament will begin approximately 3 minutes after all positions are filled.";
  }
  if (format === "unlimited") {
    return "This is a Regular format tournament. The tournament will begin at the scheduled start time if the minimum number of entrants is met. If the minimum is not met players are refunded the entry fee. ";
  }
  return "";
}

export function getPrizeDistribution(prizePercentages: number[]) {
  let msg = "The prize distribution for this tournament is as follows: ";
  prizePercentages.forEach((percentage, index) => {
    msg += `${percentage}% for ${index + 1} place, `;
  });
  return msg;
}
