import * as react from "react";
import Button from "@mui/material/Button";
import { ethers } from "ethers";
import moment from "moment";
import { useLocation, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { trimBloodlineAttribute } from "../kennel";
import { TokenInfo, TokenMetadata, Race, TokenType } from "../../../types";
import {
  useJoinRace,
  useStartRace,
  useCurrentRace,
  getTokenMetadata,
  getTokenImage,
} from "../../../services/queries";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import {
  selectAccount,
  selectSignature,
  setSignature,
  selectLottoMint1Tokens,
  selectNonce,
} from "../../../store/mainSlice";
import { signMessage } from "../../../tools/wallet";
import RaceMain from "../../race-main";
import RaceSummary from "./racesummary";
import Popup from "../../common/Popup";
import JoinPopup from "./joinpopup";
import SponsorPopup from "./sponsorpopup";
import PrizeTypeBadge from "../../common/PrizeTypeBadge";
import { ClassIndicator } from "./racelist";
import Clipboard from "../../assets/clipboard.svg";
import PlayIcon from "../../assets/play.png";
import ExternalLink from "../../assets/external-link.png";
import PlaceholderDog from "../../assets/placeholderdog.png";
import {
  formatTokenName,
  getTimeToRace,
  token2DecimalDisplay,
} from "../../../tools/helper";
import DefaultSponsor from "../../assets/icons/default_sponsor.jpeg";
import { updateBalances } from "../../../store/accountSlice";

import { BoxPositionMap } from "./racesummary";
import { Box } from "@mui/material";
import { TokenDecimals } from "../../utils";
export default function RaceDetails() {
  const { uuid } = useParams(); //RaceID
  const [searchParams, setSearchParams] = useSearchParams();
  const showResultParam = searchParams.get("showResult");
  const dispatch = useAppDispatch();
  const account = useAppSelector(selectAccount);
  const nonce = useAppSelector(selectNonce);
  const signature = useAppSelector(selectSignature);
  const [progress, setProgress] = react.useState("idle");
  const [tokenData, setTokenData] = react.useState<
    TokenMetadata[] | any | undefined
  >(undefined);
  const [showSummary, setShowSummary] = react.useState(true);
  const [showJoinPopup, setShowJoinPopup] = react.useState(false);

  //race data
  const [raceData, setRaceData] = react.useState<Race | undefined>(undefined);

  const [distRan, setDistRan] = react.useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [currentTick, setCurrentTick] = react.useState(0);
  const [maxTick, setMaxTick] = react.useState(0);
  const [entrantPosition, setEntrantPos] = react.useState<
    number[] | undefined
  >();
  const [error, setError] = react.useState("");
  const [time, setTime] = react.useState<number>(0);

  const join = useJoinRace();

  const { data, isLoading, isSuccess, isFetching, refetch } = useCurrentRace(
    uuid!
  );
  const [invisibleTimer, setInvisibleTimer] = react.useState<number>(0);
  const [raceFinished, setRaceFinished] = react.useState(false);

  const doCopy = () => {
    navigator.clipboard.writeText(window.location.href);
    let msg = document.createElement("div");
    msg.innerText = "Race URL copied to clipboard!";

    msg.style.width = "200px";
    msg.style.height = "50px";
    msg.style.backgroundColor = "grey";
    //set the div to be visible next to the clipboard
    document.getElementById("clipboard")?.appendChild(msg);
    setTimeout(() => {
      document.getElementById("clipboard")?.removeChild(msg);
    }, 2000);
  };

  react.useEffect(() => {
    if (data && isSuccess) setRaceData(data.data);
  }, [data, isSuccess]);
  //Following use effect is to start the race if user already in the page
  react.useEffect(() => {
    if (!raceData) return;
    if (progress === "idle" && raceData.startTime >= Date.now()) {
      let interval = setInterval(() => {
        setInvisibleTimer((prev) => {
          if (prev === 10) {
            console.log("refetching");
            refetch();
          }
          return Math.floor((raceData.startTime - Date.now()) / 1000);
        });
      }, 1000);
      return () => clearInterval(interval);
    }
    if (
      raceData.raceTime &&
      raceData.startTime + raceData.raceTime * 1000 < Date.now()
    ) {
      setRaceFinished(true);
    } else {
      setRaceFinished(false);
    }
  }, [progress, raceData]);

  react.useEffect(() => {
    if (raceData && raceData.raceTime) {
      if (
        raceData.status === "completed" &&
        raceData.startTime < Date.now() &&
        raceData.startTime + raceData.raceTime * 1000 > Date.now()
      ) {
        console.log("watching race");
        //TODO: fast-forward to the current tick
        watchRace();
      } else {
      }
    }
  }, [raceData, invisibleTimer]);

  const joinRace = async (tokenFamily: string, tokenId: number) => {
    await refetch();
    setError("");
    try {
      if (!signature) {
        let sig = await signMessage(nonce);
        dispatch(setSignature(sig));
        let res = await join.mutateAsync({
          raceId: uuid,
          ownerAddress: account,
          tokenId: tokenId,
          tokenFamily: tokenFamily,
          signature: sig,
        });
      } else {
        let res = await join.mutateAsync({
          raceId: uuid,
          ownerAddress: account,
          tokenId: tokenId,
          tokenFamily: tokenFamily,
          signature: signature,
        });
      }
      refetch();
      dispatch(updateBalances());
    } catch (e: any) {
      console.log(e.response.data);
      setError(e.response.data);
    }
  };
  const resetMutation = () => {
    join.reset();
  };
  const watchRace = () => {
    resetRace();
    //Do start sequence
    Promise.resolve().then(() => {
      setProgress("countdown");
      setTimeout(() => {
        setProgress("racing");
      }, 5100);
    });
  };

  const resetRace = () => {
    setProgress("idle");
    setTime(0);
    searchParams.set("showResult", "false");
    setSearchParams(searchParams);
    //setShowSummary(false);
    setCurrentTick(0);
    setDistRan([0, 0, 0, 0, 0, 0, 0, 0]);
    setEntrantPos(raceData?.joinOrder!);
    setFinishTimes([0, 0, 0, 0, 0, 0, 0, 0]);
    setFinished([false, false, false, false, false, false, false, false]);
  };

  const [finishTimes, setFinishTimes] = react.useState([
    0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [finished, setFinished] = react.useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  react.useEffect(() => {
    if (!raceData) return;
    setFinishTimes((prev) =>
      finished.map((f, i) => {
        if (f && prev[i] === 0) {
          return time;
        } else {
          return prev[i];
        }
      })
    );
    if (finished.filter((f) => f).length === (raceData.maxEntrants || 8)) {
      setProgress("finished");
      dispatch(updateBalances());
      let initDelay = setTimeout(() => {
        setShowSponsorPopup(true);
        setTimeout(() => {
          setShowSponsorPopup(false);
          searchParams.set("showResult", "true");
          setSearchParams(searchParams);
          //setShowSummary(true);
        }, 4000);
      }, 2000);

      return () => {
        clearTimeout(initDelay);
      };
    }
  }, [finished]);

  const updateDistances = (tick: number) => {
    if (!raceData) return;
    setDistRan((prev) => {
      let distCalc: number[] = [];
      for (let i = 0; i < raceData.entrants.length; i++) {
        let totalDist = raceData.raceDists[i][tick];

        if (prev[i] >= raceData.raceDistance) {
          setFinished((prev) => {
            let newFin = [...prev];
            newFin[i] = true;
            return newFin;
          });
          let joinpos = raceData.entrants[i].joinPos;
          distCalc.push(
            raceData.raceDistance +
              20 -
              raceData.outcome!.findIndex((x: any) => x.joinPos === joinpos) *
                1.2
          );
        } else {
          distCalc.push(totalDist);
        }
      }

      return distCalc;
    });
  };

  react.useEffect(() => {
    if (raceData) {
      setMaxTick(raceData.raceSpeeds ? raceData.raceSpeeds[0].length - 1 : 0);
      if (!entrantPosition) {
        setEntrantPos(raceData.joinOrder);
      }
    }
  }, [raceData, isSuccess]);

  react.useEffect(() => {
    if (progress === "racing") {
      const interval = setInterval(() => {
        //server ticks are every 100ms so we need to update the time every 100ms
        //however we want to setInterval 10ms for smoothness
        updateDistances(currentTick);
      }, 50);
      return () => {
        clearInterval(interval);
      };
    }
  }, [currentTick, progress]);

  react.useEffect(() => {
    if (progress === "racing") {
      let startTime = Date.now();
      const interval = setInterval(() => {
        let delta = Date.now() - startTime;
        setTime((prevTime) => delta);
      }, 50);
      return () => {
        clearInterval(interval);
      };
    }
  }, [progress]);

  react.useEffect(() => {
    if (progress === "racing") {
      const interval = setInterval(() => {
        setCurrentTick((prev) => {
          return prev + 1 < maxTick ? prev + 1 : prev;
        }); // check if max tick data reached
      }, 100);
      return () => {
        clearInterval(interval);
      };
    }
  }, [progress]);

  const [joinPosToDistRanIndex, setJoinPosToDistRanIndex] = react.useState(
    new Map<number, number>()
  );
  react.useEffect(() => {
    if (!raceData) return;
    var len = raceData.entrants.length;
    var joinPosToDistRanIndex = new Map<number, number>(); // map object
    var indices = new Array(len);

    for (var i = 0; i < len; ++i) {
      indices[i] = raceData!.entrants[i].joinPos;
      joinPosToDistRanIndex.set(raceData!.entrants[i].joinPos, i);
    }

    setJoinPosToDistRanIndex(joinPosToDistRanIndex);
  }, [raceData]);

  // sorted for entrant position to display current positions of dogs in the race
  react.useEffect(() => {
    if (progress === "racing") {
      var indices = Array.from(joinPosToDistRanIndex.keys());

      indices.sort(function (a, b) {
        // use the mapping object to get the distRan index for a and b
        var distRanIndexA = joinPosToDistRanIndex.get(a);
        var distRanIndexB = joinPosToDistRanIndex.get(b);

        // get outcome index

        if (
          distRan[distRanIndexA!] === undefined ||
          distRan[distRanIndexB!] === undefined
        ) {
          return 0;
        }
        if (
          distRan[distRanIndexA!] >= raceData!.raceDistance &&
          distRan[distRanIndexB!] < raceData!.raceDistance
        ) {
          return -1; // A has finished, B has not, so A is "greater"
        }
        if (
          distRan[distRanIndexB!] >= raceData!.raceDistance &&
          distRan[distRanIndexA!] < raceData!.raceDistance
        ) {
          return 1; // B has finished, A has not, so B is "greater"
        }
        if (
          distRan[distRanIndexA!] >= raceData!.raceDistance &&
          distRan[distRanIndexB!] >= raceData!.raceDistance
        ) {
          var outcomeA = raceData!.outcome!.findIndex(
            (x: any) => x.joinPos === a
          );
          var outcomeB = raceData!.outcome!.findIndex(
            (x: any) => x.joinPos === b
          );

          return outcomeA < outcomeB ? -1 : outcomeA > outcomeB ? 1 : 0;
        }
        // Neither has finished, so the one with the larger distRan is "greater"
        return distRan[distRanIndexA!] < distRan[distRanIndexB!]
          ? 1
          : distRan[distRanIndexA!] > distRan[distRanIndexB!]
          ? -1
          : 0;
      });

      setEntrantPos(indices);
    }
  }, [distRan, joinPosToDistRanIndex]);

  const updateTokenInfo = react.useCallback(async () => {
    if (!raceData) return;
    let toUpdate: any[] = Array(raceData.maxEntrants || 8).fill(0);
    if (raceData.entrants) {
      for (let i = 0; i < raceData.entrants.length; i++) {
        let boxNumber = raceData.entrants[i].joinPos;
        let tokenInfo = await getTokenMetadata(
          raceData.entrants[i].tokenId,
          raceData.entrants[i].tokenFamily
        );

        toUpdate[i] = {
          ...tokenInfo,
          kennelName: raceData.entrants[i].kennel || "",
          boxNumber: boxNumber,
        };
      }
    }

    setTokenData(toUpdate);
  }, [raceData]);

  react.useEffect(() => {
    updateTokenInfo();
  }, [raceData]);

  const closePopup = () => {
    setShowJoinPopup(false);
    let content = document.getElementById("root");
    content!.style.overflow = "";
  };

  const openPopup = async () => {
    setShowJoinPopup(true);

    let content = document.getElementById("root");
    content!.style.overflow = "hidden";
    await refetch();
  };

  const countdown = getTimeToRace(raceData?.startTime || 0);

  const [showSponsorPopup, setShowSponsorPopup] = react.useState(false);

  const [showWatch3dHover, setShowWatch3dHover] = react.useState(false);

  const fee_token =
    raceData && raceData.fee_token ? raceData.fee_token : TokenType.USDT;
  const tokenDisplayName = formatTokenName(fee_token);

  return (
    <>
      {showSponsorPopup ? (
        <>
          <Popup>
            <SponsorPopup
              sponsorLink={raceData!.sponsorLink || ""}
              sponsorMessage={
                raceData!.sponsorMessage ||
                "This race is proudly brought to you by..."
              }
              sponsorImage={raceData!.sponsorImage || DefaultSponsor}
            ></SponsorPopup>
          </Popup>
        </>
      ) : (
        <></>
      )}
      {showJoinPopup && raceData && (
        <Popup>
          <JoinPopup
            handleJoin={joinRace}
            handleClose={closePopup}
            handleReset={resetMutation}
            raceData={raceData}
            joinStatus={join}
            error={error}
          ></JoinPopup>
        </Popup>
      )}

      <div className="race-detail">
        {isLoading ? (
          <>
            <div>Loading Race Data...</div>
          </>
        ) : (
          <></>
        )}
        {raceData && (
          <div className="race-game-event-main">
            <div className="race-game-event">
              <div className="race-track-detail">
                <div className="titles grid-5">
                  <div>Race Event</div>
                  <div>Location</div>
                  <div>Track Conditions</div>
                  <div>Weather</div>
                  {raceData.sponsorImage ? <div>Sponsor</div> : <div></div>}
                </div>
                <div className="info-bar">
                  <div className="grid-5">
                    <span className="trackname">
                      <span className="name">{raceData.raceName} : </span>
                      <span className="status">
                        {Date.now() < raceData.startTime
                          ? `${countdown.hours ? countdown.hours + "h" : ""} ${
                              countdown.minutes ? countdown.minutes + "m" : ""
                            } ${
                              countdown.seconds ? countdown.seconds + "s" : ""
                            }`
                          : Date.now() <
                            (raceData?.raceTime || 0) * 1000 +
                              raceData.startTime +
                              5 * 1000 //countdown
                          ? raceData.status === "cancelled"
                            ? " Abandoned"
                            : " Live"
                          : raceData.status === "cancelled"
                          ? " Abandoned"
                          : raceData.raceTime
                          ? " Finished"
                          : " Waiting"}
                      </span>
                    </span>
                    <div className="planet">
                      <span id="clipboard">
                        {raceData.raceLocation || "BetNFT"}{" "}
                      </span>
                      <img
                        src={Clipboard}
                        className="clipboard"
                        onClick={() => doCopy()}
                      ></img>
                    </div>
                    <div className="track-conditions">
                      {raceData.raceCondition}
                    </div>
                    <div className="track-conditions">
                      {raceData.raceWeather}
                    </div>
                    {raceData.sponsorImage ? (
                      <div className="sponsor">
                        <div className="msg">
                          {raceData.sponsorMessage ||
                            "This race is proudly brought to you by..."}
                        </div>
                        <a href={raceData.sponsorLink || ""} target="_blank">
                          <img
                            src={raceData.sponsorImage}
                            alt="sponsor"
                            className=""
                          />
                        </a>
                      </div>
                    ) : (
                      //Default BETNFT logo
                      <div className="sponsor">
                        <div className="msg">
                          This race is proudly brought to you by...
                        </div>
                        <a>
                          <img
                            src={DefaultSponsor}
                            alt="sponsor"
                            className=""
                          />
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="wrapper">
                    {raceData.entrants.length !== raceData.maxEntrants && (
                      <>
                        <div
                          className="watch-3d"
                          onMouseEnter={() => setShowWatch3dHover(true)}
                          onMouseLeave={() => setShowWatch3dHover(false)}
                          style={{ position: "relative", cursor: "default" }}
                        >
                          Watch 3D <img src={ExternalLink}></img>
                          {showWatch3dHover &&
                            raceData.entrants.length !==
                              raceData.maxEntrants && (
                              <div className="custom-tooltip">
                                <div className="innerdiv">
                                  <label className="">
                                    Available once race is filled
                                  </label>
                                </div>
                              </div>
                            )}
                        </div>
                      </>
                    )}
                    {raceData.entrants.length === raceData.maxEntrants && (
                      <a
                        className="watch-3d"
                        href={`${process.env.REACT_APP_3D_URL}/race/${raceData.raceId}`}
                        onMouseEnter={() => setShowWatch3dHover(true)}
                        onMouseLeave={() => setShowWatch3dHover(false)}
                        style={{ position: "relative" }}
                      >
                        Watch 3D <img src={ExternalLink}></img>
                      </a>
                    )}

                    {/* <div className="live">
                      <img src={PlayIcon}></img>
                      Live
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="extra-info-bar">
                <div className="info event">
                  Event Type{" "}
                  <ClassIndicator>{raceData.raceClass}</ClassIndicator>
                </div>
                <div className="info">
                  <PrizeTypeBadge
                    prizePercentages={raceData.prizePercentages}
                  ></PrizeTypeBadge>
                </div>
                <div className="info">
                  Distance <span>{raceData.raceDistance}m</span>
                </div>
                <div className="info">
                  Status
                  <span>
                    {raceData.cancelled
                      ? "Abandoned"
                      : raceFinished
                      ? "Completed"
                      : "Live"}
                  </span>
                </div>
                <div className="info">
                  Time Elapsed <span>{formatTimer(time)}</span>
                </div>
                <div className="info">
                  Entry Fee
                  <span>
                    {!raceData.entryFee || raceData.entryFee === "0"
                      ? "Free"
                      : `${token2DecimalDisplay(
                          raceData.entryFee,
                          fee_token
                        )} ${tokenDisplayName}`}
                  </span>
                </div>
                <div className="info">
                  Prize Pool
                  <span>
                    {raceData.prizePool
                      ? `${token2DecimalDisplay(
                          raceData.prizePool,
                          fee_token
                        )} `
                      : "$0 "}
                    {tokenDisplayName}
                  </span>
                </div>
                <div className="info">
                  Registered
                  <span>
                    {raceData.entrants
                      ? `${raceData.entrants.length}/${
                          raceData.maxEntrants || 8
                        }`
                      : `0/${raceData.maxEntrants || 8}`}
                  </span>
                </div>

                {!raceFinished &&
                  raceData.entrants.length < (raceData.maxEntrants || 8) &&
                  !(
                    raceData.status === "cancelled" ||
                    raceData.status === "completed"
                  ) &&
                  !raceData.type?.includes("tournament") && (
                    <>
                      <div className="info info-btn">
                        <Button
                          variant="contained"
                          onClick={() => {
                            openPopup();
                          }}
                        >
                          Join Race
                        </Button>
                      </div>
                    </>
                  )}
                {raceFinished && raceData.status !== "cancelled" && (
                  <>
                    <div className="info info-btn">
                      <Button
                        onClick={watchRace}
                        style={{ fontWeight: 800 }}
                        className="ReplyBtn"
                      >
                        Watch Replay
                      </Button>
                    </div>
                    <div className="info info-btn">
                      <Button
                        style={{ fontWeight: 800 }}
                        onClick={() => {
                          resetRace();
                          searchParams.set("showResult", "true");
                          setSearchParams(searchParams);
                          //setShowSummary(true);
                        }}
                        className="ReplyBtn"
                      >
                        Show Results
                      </Button>
                    </div>
                    {/* <Button onClick={resetRace}>// Reset Track // </Button> */}
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="blackbar"></div>
        {/* TODO: Dont hide unless after race is completed*/}
        {raceData ? (
          <>
            {tokenData && showResultParam === "true" && (
              <RaceSummary
                raceData={raceData}
                fromLiveRace={true}
              ></RaceSummary>
            )}
            {showResultParam !== "true" && tokenData && (
              <div className="race-area">
                <div className="sections">
                  <div className="progress-data">
                    <div
                      className="positions"
                      style={{
                        visibility:
                          progress === "racing" || progress === "finished"
                            ? "visible"
                            : "hidden",
                      }}
                    >
                      <div className={"icon first"}>1st</div>
                      <div className={"icon second"}>2nd</div>
                      <div
                        className={`icon third ${
                          (raceData.maxEntrants ? raceData.maxEntrants : 8) <=
                            2 && "hidden"
                        }`}
                      >
                        3rd
                      </div>
                      <div
                        className={`icon ${
                          (raceData.maxEntrants ? raceData.maxEntrants : 8) <=
                            3 && "hidden"
                        }`}
                      >
                        4th
                      </div>
                      <div
                        className={`icon ${
                          (raceData.maxEntrants ? raceData.maxEntrants : 8) <=
                            4 && "hidden"
                        }`}
                      >
                        5th
                      </div>
                      <div
                        className={`icon ${
                          (raceData.maxEntrants ? raceData.maxEntrants : 8) <=
                            5 && "hidden"
                        }`}
                      >
                        6th
                      </div>
                      <div
                        className={`icon ${
                          (raceData.maxEntrants ? raceData.maxEntrants : 8) <=
                            6 && "hidden"
                        }`}
                      >
                        7th
                      </div>
                      <div
                        className={`icon ${
                          (raceData.maxEntrants ? raceData.maxEntrants : 8) <=
                            7 && "hidden"
                        }`}
                      >
                        8th
                      </div>
                    </div>
                    <div className="racers">
                      {tokenData.map((token: any, index: number) => {
                        if (!token) return;
                        let bloodlineIndex = token.attributes.findIndex(
                          (e: any) => e.trait_type === "Bloodline"
                        );
                        let [bloodlineTag, bloodlineName] =
                          trimBloodlineAttribute(
                            token.attributes[bloodlineIndex!].value
                          );

                        return (
                          <div
                            key={index.toString() + token.edition}
                            className="row"
                            style={{
                              top: `calc(${
                                (progress === "racing" ||
                                  progress === "finished") &&
                                entrantPosition
                                  ? entrantPosition.indexOf(token.boxNumber) *
                                    12.5
                                  : token.boxNumber * 12.5
                              }% + 5px)`,
                            }}
                          >
                            <div className="dog">
                              <div className="img-container">
                                <img
                                  src={
                                    token.image
                                      ? getTokenImage(token.image)
                                      : PlaceholderDog
                                  }
                                ></img>
                              </div>

                              {/* <img src={DividerTest} className="divider"></img> */}
                              <div className="name-type">
                                <div className="name">
                                  {token.name}
                                  {finished[index] === true &&
                                    " - " +
                                      raceData.entrants![index].time.toFixed(2)}
                                </div>
                                <div className="type">
                                  {bloodlineTag + " - " + token.kennelName ||
                                    bloodlineTag}
                                </div>
                              </div>
                              <div className="number">
                                <img
                                  src={
                                    BoxPositionMap[
                                      (token.boxNumber +
                                        1) as keyof typeof BoxPositionMap
                                    ]
                                  }
                                ></img>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {data && (
                    <div className="racetrack">
                      <Countdown progress={progress}></Countdown>
                      <RaceMain
                        race={raceData}
                        progress={progress}
                        distances={distRan}
                        maxEntrants={raceData.maxEntrants || 8}
                        laneOrder={updateLanesToRender(
                          raceData.entrants.map((e) => e.joinPos)
                        )}
                      ></RaceMain>
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="loading">Loading...</div>
        )}
      </div>
    </>
  );
}
let lanesToRender = [-1, -1, -1, -1, -1, -1, -1, -1];
const updateLanesToRender = (laneOrder: number[]) => {
  let updated = lanesToRender.map((lane, i) => {
    return laneOrder.indexOf(i) == -1 ? -1 : laneOrder.indexOf(i);
  });

  lanesToRender = updated;
  return updated;
};

interface CountdownProps extends React.HTMLAttributes<HTMLDivElement> {
  progress: string;
}

function Countdown(props: CountdownProps) {
  const [count, setCount] = react.useState(5);
  react.useEffect(() => {
    if (props.progress === "countdown") {
      let countdown = setInterval(() => {
        setCount((prev) => {
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(countdown);
    } else {
      setTimeout(() => {
        setCount(5);
      }, 1000);
    }
  }, [props.progress]);
  return (
    <>
      <div
        className="countdown"
        style={{
          visibility: props.progress === "countdown" ? "visible" : "hidden",
          opacity: props.progress === "countdown" ? "1" : "0",
          color: count > 0 ? "" : "yellow",
        }}
      >
        {count > 0 ? count : "Go!"}
      </div>
    </>
  );
}

const formatTimer = (time: number) => {
  let seconds = (time % 60000) / 1000;

  var minutes = Math.floor(time / 60000);
  let _min = minutes > 0 ? `${minutes}m` : "";
  let formatted = `${_min} ${seconds.toFixed(2)}s`;
  return formatted;
};
