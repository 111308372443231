import * as react from "react";
import React, { useState } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { Box, Menu, MenuItem, Switch } from "@mui/material";
import downArrow from "../../../assets/down-arrow-green.svg";
import { HighlightOff } from "@mui/icons-material";
import { useAppSelector } from "../../../../app/hooks";
import { selectAccount } from "../../../../store/mainSlice";
import { CircularProgress } from "@mui/material";
import {
  useGetKennelLeaderboard,
  useGetTokenLeaderboard,
} from "../../../../services/queries";
import PrizeLeaderboard from "./PrizeLeaderboard";
import WinsLeaderBoards from "./WinsLeaderboard";

const data = [{ name: "Greyhounds" }, { name: "Kennels" }];

const bloodline = [
  { name: "G1", color: "#2a9d8f" },
  { name: "G2", color: "#bc6c25" },
  { name: "G3", color: "#669bbc" },
  { name: "G4", color: "#004e89" },
];

const LeaderBoards = () => {
  const [val, setval] = useState("Greyhounds");
  const [raceEnter, setRaceEnter] = react.useState<null | HTMLElement>(null); // main race to enter menu
  let account = useAppSelector(selectAccount);
  const [showAll, setShowAll] = useState(true);
  const [greyhoundFilter, setGreyhoundFilter] = useState("All");

  const [leaderboardDisplay, setLeaderboardDisplay] = useState("Prizes"); // sort by Prizes or Wins

  const openRaceEnter = Boolean(raceEnter);

  const kennelLeaderboard = useGetKennelLeaderboard();
  const tokenLeaderboard = useGetTokenLeaderboard(
    greyhoundFilter,
    showAll ? "" : account
  );

  const { data: kennelData, isLoading: kennelLoading } = kennelLeaderboard;
  const { data: tokenData, isLoading: tokenLoading } = tokenLeaderboard;

  return (
    <div className="leaderboard-main">
      <div className="title-sec">
        <div
          className={`${leaderboardDisplay === "Prizes" && "selected"}`}
          onClick={() => {
            setLeaderboardDisplay("Prizes");
          }}
        >
          Prize Money Leaderboard
        </div>
        <div
          className={`${leaderboardDisplay === "Wins" && "selected"}`}
          onClick={() => {
            setLeaderboardDisplay("Wins");
          }}
        >
          Wins Leaderboard
        </div>
      </div>
      {leaderboardDisplay === "Prizes" && (
        <>
          <PrizeLeaderboard></PrizeLeaderboard>
        </>
      )}
      {leaderboardDisplay === "Wins" && (
        <>
          <WinsLeaderBoards></WinsLeaderBoards>
        </>
      )}
    </div>
  );
};

export default LeaderBoards;
