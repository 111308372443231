import axios from "axios";
import LottoMintABI from "../ABI/BETNFT.json";
import OldABI from "../ABI/OLDABI.json";

export const CONTRACT_ADDRESS = "0xEa2FF666F23F9CB3bF0f4dc1836e89f971DFC966";
export const G1_CONTRACT_ADDRESS = "0x69a3c5cc53d17b2aff705cee97bdfa94e8a47370";
export const G2_CONTRACT_ADDRESS = "0xc2cf058556f34c69b3c458861dab3064bc590160";
export const G3_CONTRACT_ADDRESS = "0xf5A54e3f9cA5007F3163B36AbEC5e4486889324D";
export const G4_CONTRACT_ADDRESS = "0xbe336776f2C1315E66Cd65F4622285955AFf99e1";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
});

export const externalAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
});

export const BetNFTContracts = [
  {
    //Lotto mint - contains all bloodlines
    name: "LottoMint1",
    contract: "0xEa2FF666F23F9CB3bF0f4dc1836e89f971DFC966",
    ipfsFolder: "QmaGqnRKjtJjii4eBdMyZJYoyBemCETc295YhmC2q2JqKU",
    ABI: LottoMintABI,
  },
  {
    name: "G1",
    contract: "0x69a3c5cc53d17b2aff705cee97bdfa94e8a47370",
    ABI: OldABI,
  },
  {
    name: "G2",
    contract: "0xc2cf058556f34c69b3c458861dab3064bc590160",
    ABI: OldABI,
  },
  {
    name: "G3",
    contract: "0xf5A54e3f9cA5007F3163B36AbEC5e4486889324D",
    ABI: OldABI,
  },
  {
    name: "G4",
    contract: "0xbe336776f2C1315E66Cd65F4622285955AFf99e1",
    ABI: OldABI,
  },
];
