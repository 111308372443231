import "./style.scss";
import Button from "@mui/material/Button";
import Logo from "../../assets/logo.svg";
import Discord from "../../assets/icons/discord.png";
import Facebook from "../../assets/icons/facebook.png";
import Instagram from "../../assets/icons/instagram.png";
import Telegram from "../../assets/icons/telegram.png";
import Twitter from "../../assets/icons/twitter.png";
import TikTok from "../../assets/icons/tiktok.png";
import { Container } from "@mui/material";
export default function Footer() {
  const d = new Date();
  let currentYear = d.getFullYear();
  return (
    <div className="footer">
      <Container>
        <div className="footer-data">
          <div className="logo-copyright">
            <img src={Logo}></img>
            <span>Copyright &copy; BETNFT {currentYear}</span>
          <span style={{display:'block', textAlign:'center'}}>BETA</span>
          </div>
          <div className="group-1">
            <div className="link-container">
              <div className="about">
                <h2>About Us</h2>
                <a
                  href="https://betnft.gitbook.io/welcome-to-gitbook/betnft/betnft-overview"
                  className="link"
                  target="_blank"
                >
                  White Paper (Gitbook)
                </a>
                <a
                  href="https://betnft.gitbook.io/welcome-to-gitbook/betnft-token/usdbetnft-tokenomics"
                  className="link"
                  target="_blank"
                >
                  Tokenomics
                </a>
                <a
                  href="https://betnft.gitbook.io/welcome-to-gitbook/team"
                  className="link"
                  target="_blank"
                >
                  Team
                </a>
                <a
                  href="https://betnft.gitbook.io/welcome-to-gitbook/business-model/roadmap"
                  className="link"
                  target="_blank"
                >
                  Roadmap
                </a>
                <a
                  href="https://discordapp.com/channels/907415496503222302/907416415131291688"
                  className="link"
                  target="_blank"
                >
                  Announcements
                </a>
                <a
                  href="https://betnft.gitbook.io/welcome-to-gitbook/faqs "
                  className="link"
                  target="_blank"
                >
                  FAQ
                </a>
              </div>
              <div className="social">
                <h2>Social</h2>
                <div className="content">
                  <div className="left">
                    <a
                      className="media-link"
                      href="https://t.me/joinchat/sCQ4PsX1OPFkNzE1"
                      target="_blank"
                    >
                      <img src={Telegram}></img>Telegram
                    </a>
                    <a
                      className="media-link"
                      href="https://twitter.com/BetnftRun"
                      target="_blank"
                    >
                      <img src={Twitter}></img>Twitter
                    </a>
                    <a
                      className="media-link"
                      href="https://discord.com/invite/yjAxCemAyF"
                      target="_blank"
                    >
                      <img src={Discord}></img>Discord
                    </a>
                  </div>
                  <div className="right">
                    <a
                      className="media-link"
                      href="https://www.instagram.com/betnft.run/"
                      target="_blank"
                    >
                      <img src={Instagram}></img>Instagram
                    </a>
                    <a
                      className="media-link"
                      href="https://www.facebook.com/profile.php?id=100075125229431"
                      target="_blank"
                    >
                      <img src={Facebook}></img>Facebook
                    </a>
                    <a
                      className="media-link"
                      href="https://vm.tiktok.com/ZSe63MADR/"
                      target="_blank"
                    >
                      <img src={TikTok}></img>Tiktok
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact">Contact: admin@betnft.run</div>
            <div className="legals">
              <span>18+</span>
              <a
                href="https://betnft.gitbook.io/welcome-to-gitbook/terms-and-conditions"
                target="_blank"
              >
                Terms & Conditions
              </a>
              <a
                href="https://betnft.gitbook.io/welcome-to-gitbook/privacy-policy"
                target="_blank"
              >
                Privacy Policy
              </a>
            </div>
          </div>
          <span className="copy-benifit">Copyright &copy; BETNFT {currentYear}</span>
          {/* <span >BETA</span> */}
        </div>
      </Container>
    </div>
  );
}
