import "./style.scss";
import React, * as react from "react";
import { ethers } from "ethers";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import {
  selectAccount,
  selectSignature,
  selectAllTokens,
  selectLottoMint1Tokens,
  selectG1Tokens,
  selectG2Tokens,
  selectG3Tokens,
  selectG4Tokens,
  updateTokenInfo,
  selectNonce,
  TokenFamily,
} from "../../../store/mainSlice";
import { Button } from "@mui/material";
import InfoIcon from "../../assets/info.png";
import Naming from "./naming";
import SearchIcon from "../../assets/search.svg";
import FilterIcon from "../../assets/filter-results-button.png";
import DownArrow from "../../assets/down-arrow.svg";
import PlaceholderPic from "../../assets/placeholderpic.png";
import KennelSettings from "./kennelsettings";
import Popup from "../../common/Popup";
import PlaceholderDog from "../../assets/placeholderdog.png";
import LogoWShadow from "../../assets/logowshadow.png";
import Clipboard from "../../assets/clipboard.svg";
import PlaceholderDetailDog from "../../assets/placeholderdetaildog.png";
import DetailIcon from "../../assets/leaping.png";
import BreedIcon from "../../assets/paw.png";
import Cog from "../../assets/cog.svg";
import { TokenInfo, TokenType } from "../../../types";
import {
  getTokenImage,
  useChangeName,
  useGetKennel,
  useTokenInfo,
  useUpdateKennel,
} from "../../../services/queries";
import { signMessage } from "../../../tools/wallet";
import {
  formatTokenAmount,
  getBloodlineCount,
  token2DecimalDisplay,
} from "../../../tools/helper";
import Sidebar from "./Sidebar";
// import { stat } from "fs";

const NumberMap = {
  "1": "One",
  "2": "Two",
  "3": "Three",
  "4": "Four",
};

type SidebarState = {
  isOpen?: boolean;
  bloodlineOpen: boolean;
  genderOpen: boolean;
  percentageOpen: boolean;
  bloodline: string[];
  gender: string[];
  percentage: string[];
};

// const Datarray = [
//   {
//     metadata: {
//       name: "Redhound #27",
//       description:
//         "BetNFT Greyhound - A unique NFT Asset with each greyhound having varying visual and hidden attributes for participating in our races.",
//       image: "ipfs://QmRC7qTcDdRpEmaMWuxJvjQkYrSfM94xuq8tQhb7LFKQyT/27.png",
//       dna: "ce2760ba2bd75819933490b52674ce0559c781f3",
//       edition: 27,
//       date: 1646138760254,
//       attributes: [
//         {
//           trait_type: "Bloodline",
//           value: "G1 Black Top",
//         },
//         {
//           trait_type: "Breed level",
//           value: "B.L 001",
//         },
//         {
//           trait_type: "Gender",
//           value: "Dog",
//         },
//         {
//           trait_type: "Skin Pattern",
//           value: "Space",
//         },
//         {
//           trait_type: "Coat Colour4",
//           value: "Copper",
//         },
//       ],
//       compiler: "HashLips Art Engine",
//     },
//     ownerAddress: "0xe163f04d59e0b1c65e5f2a469c2677461d0f64a2",
//     tokenFamily: "LottoMint1",
//     tokenId: 27,
//   },
//   {
//     metadata: {
//       name: "Greyhound #28",
//       description:
//         "BetNFT Greyhound - A unique NFT Asset with each greyhound having varying visual and hidden attributes for participating in our races.",
//       image: "ipfs://QmRC7qTcDdRpEmaMWuxJvjQkYrSfM94xuq8tQhb7LFKQyT/27.png",
//       dna: "ce2760ba2bd75819933490b52674ce0559c781f3",
//       edition: 28,
//       date: 1646138760254,
//       attributes: [
//         {
//           trait_type: "Bloodline",
//           value: "G2 Zoom Top",
//         },
//         {
//           trait_type: "Breed level",
//           value: "B.L 001",
//         },
//         {
//           trait_type: "Gender",
//           value: "Dog",
//         },
//         {
//           trait_type: "Skin Pattern",
//           value: "Space",
//         },
//         {
//           trait_type: "Coat Colour4",
//           value: "Copper",
//         },
//       ],
//       compiler: "HashLips Art Engine",
//     },
//     ownerAddress: "0xe163f04d59e0b1c65e5f2a469c2677461d0f64a2",
//     tokenFamily: "LottoMint1",
//     tokenId: 28,
//   },
//   {
//     metadata: {
//       name: "Whitehound #29",
//       description:
//         "BetNFT Greyhound - A unique NFT Asset with each greyhound having varying visual and hidden attributes for participating in our races.",
//       image: "ipfs://QmRC7qTcDdRpEmaMWuxJvjQkYrSfM94xuq8tQhb7LFKQyT/27.png",
//       dna: "ce2760ba2bd75819933490b52674ce0559c781f3",
//       edition: 29,
//       date: 1646138760254,
//       attributes: [
//         {
//           trait_type: "Bloodline",
//           value: "G3 Temlee",
//         },
//         {
//           trait_type: "Breed level",
//           value: "B.L 001",
//         },
//         {
//           trait_type: "Gender",
//           value: "Bitch",
//         },
//         {
//           trait_type: "Skin Pattern",
//           value: "Space",
//         },
//         {
//           trait_type: "Coat Colour4",
//           value: "Copper",
//         },
//       ],
//       compiler: "HashLips Art Engine",
//     },
//     ownerAddress: "0xe163f04d59e0b1c65e5f2a469c2677461d0f64a2",
//     tokenFamily: "LottoMint1",
//     tokenId: 29,
//   },
//   {
//     metadata: {
//       name: "Whitehound #31",
//       description:
//         "BetNFT Greyhound - A unique NFT Asset with each greyhound having varying visual and hidden attributes for participating in our races.",
//       image: "ipfs://QmRC7qTcDdRpEmaMWuxJvjQkYrSfM94xuq8tQhb7LFKQyT/27.png",
//       dna: "ce2760ba2bd75819933490b52674ce0559c781f3",
//       edition: 31,
//       date: 1646138760254,
//       attributes: [
//         {
//           trait_type: "Bloodline",
//           value: "G4 Half Your Luck",
//         },
//         {
//           trait_type: "Breed level",
//           value: "B.L 001",
//         },
//         {
//           trait_type: "Gender",
//           value: "Bitch",
//         },
//         {
//           trait_type: "Skin Pattern",
//           value: "Space",
//         },
//         {
//           trait_type: "Coat Colour4",
//           value: "Copper",
//         },
//       ],
//       compiler: "HashLips Art Engine",
//     },
//     ownerAddress: "0xe163f04d59e0b1c65e5f2a469c2677461d0f64a2",
//     tokenFamily: "LottoMint1",
//     tokenId: 31,
//   },
// ];

interface KennelListProps {
  filterTokenData: TokenInfo[] | undefined;
  setFilterTokenData: React.Dispatch<React.SetStateAction<TokenInfo[]>>;
  tokenData: TokenInfo[];
  showDetails: (index: number) => void;
  filterString: string;
}

function KennelList({
  filterTokenData,
  setFilterTokenData,
  tokenData,
  showDetails,
  filterString,
}: KennelListProps) {
  return (
    <>
      {filterTokenData &&
        filterTokenData?.map((token: TokenInfo, index: number) => {
          let bloodlineAttrIndex = token.metadata?.attributes.findIndex(
            (attr) =>
              attr.trait_type === "Bloodline" || attr.trait_type === "bloodline"
          );
          let [bloodlineTag, bloodlineName] = trimBloodlineAttribute(
            token.metadata?.attributes[bloodlineAttrIndex!].value
          );
          // const match =
          //   token.metadata?.name
          //     .toLowerCase()
          //     .substring(0, filterString.length) === filterString.toLowerCase();
          return (
            // match && (
            <div
              className="row"
              onClick={() => showDetails(index)}
              key={token.tokenId}
            >
              <div className="img-container">
                <img
                  src={
                    token.metadata
                      ? getTokenImage(token.metadata?.image)
                      : PlaceholderDog
                  }
                  alt=""
                ></img>
              </div>
              <div className="kennel-table-main">
                <div className="d-flex">
                  {/* <img src={DividerTest} className="divider"></img> */}
                  <span className="dog-name">
                    {/* TODO: Use token name  */}
                    {token.metadata?.name}
                  </span>
                  <span className="bloodline">
                    {bloodlineTag + " - " + bloodlineName}
                  </span>
                </div>
                <div className="d-flex">
                  <Fatigue value={100}></Fatigue>
                  <span className="qunt">Q 0/0</span>
                </div>
              </div>
            </div>
          );
          // );
        })}
    </>
  );
}

interface KennelProps {
  tokenData: TokenInfo;
  submitName: (tokenFamily: string, tokenId: number, name: string) => void;
  toggleNamingPopup: () => void;
}

function KennelDetail(props: KennelProps) {
  const { tokenData, submitName, toggleNamingPopup } = props;
  let bloodlineAttrIndex = tokenData.metadata?.attributes.findIndex(
    (attr) => attr.trait_type === "Bloodline"
  );
  let breedLevelAttrIndex = tokenData.metadata?.attributes.findIndex(
    (attr) => attr.trait_type === "Breed level"
  );
  let genderAttrIndex = tokenData.metadata?.attributes.findIndex(
    (attr) => attr.trait_type === "Gender"
  );
  let coatColorAttrIndex = tokenData.metadata?.attributes.findIndex(
    (attr) =>
      attr.trait_type === "Coat Colour" || attr.trait_type === "Coat Colour4"
  );
  let [bloodlineTag, bloodlineName] = trimBloodlineAttribute(
    tokenData.metadata?.attributes[bloodlineAttrIndex!].value
  );
  const [nameInput, setNameInput] = react.useState<string>("");
  const tokenInfo = useTokenInfo(
    tokenData.tokenId.toString(),
    tokenData.tokenFamily
  );

  return (
    <>
      <div className="detail-header">
        <div>
          {tokenData.metadata?.name}
          {/* TODO: Use different metadata to check naming instead of just UI */}
          {tokenData.metadata?.name.match(/Greyhound #*/) && (
            <Button
              variant={"contained"}
              onClick={() => toggleNamingPopup()}
              style={{ marginLeft: "15px", background: "var(--main-green)" }}
            >
              Change Name
            </Button>
          )}
        </div>
        {/* <span className="dog-class">Class 03</span>{" "} */}
      </div>
      <div className="kennel-detail">
        <div className="detail-content">
          <div className="dog-image">
            <img
              src={
                tokenData.metadata
                  ? getTokenImage(tokenData.metadata?.image)
                  : PlaceholderDog
              }
              alt={tokenData.metadata?.name || "dog"}
            ></img>
          </div>
          <Fatigue value={100}></Fatigue>
          <div className="extra-icons">
            <div className="group">
              DETAILS <img src={DetailIcon} alt=""></img>
            </div>
            <div className="group">
              BREED <img src={BreedIcon} alt=""></img>
            </div>
          </div>
        </div>
        <div className="detailed-info">
          <div className="detail">
            <span className="title">BREED LEVEL</span>
            <span className="value">
              {tokenData.metadata?.attributes[breedLevelAttrIndex!].value}
            </span>
          </div>
          <div className="detail">
            <span className="title">BLOODLINE</span>
            <span className="value">
              {bloodlineTag} - {bloodlineName}
            </span>
          </div>
          <div className="detail">
            <span className="title">GENDER</span>
            <span className="value">
              {tokenData.metadata?.attributes[genderAttrIndex!].value}
            </span>
          </div>
          <div className="detail">
            <span className="title">COAT</span>
            <span className="value">
              {tokenData.metadata?.attributes[coatColorAttrIndex!].value}
            </span>
          </div>
          <div className="detail">
            <span className="title">RACES</span>
            <span className="value">
              {tokenInfo.data?.data.completed_races || 0}
            </span>
          </div>
          <div className="detail">
            <span className="title">CAREER STATS</span>
            <span className="value">
              {tokenInfo.data
                ? `${tokenInfo.data?.data.career.firsts}/${tokenInfo.data?.data.career.seconds}/${tokenInfo.data?.data.career.thirds}`
                : "0/0/0"}
            </span>
          </div>
          <div className="detail">
            <span className="title">WIN PERCENTAGE</span>
            <span className="value">
              {`${(tokenInfo.data
                ? (tokenInfo.data?.data.career.firsts * 100) /
                  tokenInfo.data?.data.completed_races
                : 0
              ).toFixed(2)}%`}
            </span>
          </div>
          <div className="detail">
            <span className="title">PLACE PERCENTAGE</span>
            <span className="value">
              {`${(tokenInfo.data
                ? ((tokenInfo.data?.data.career.seconds +
                    tokenInfo.data?.data.career.thirds) *
                    100) /
                  tokenInfo.data?.data.completed_races
                : 0
              ).toFixed(2)}%`}
            </span>
          </div>
          <div className="detail">
            <span className="title">PRIZE MONEY</span>
            <span className="value">
              {`${
                tokenInfo.data
                  ? token2DecimalDisplay(
                      tokenInfo.data?.data.new_total_earnings.USDT,
                      TokenType.USDT
                    )
                  : "$0.00"
              }`}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export function trimBloodlineAttribute(bloodline: string) {
  return [bloodline.substring(0, 2), bloodline.substring(3, bloodline.length)];
}

interface FatigueProps {
  value: string | number;
}

function Fatigue({ value }: FatigueProps) {
  return (
    <>
      <div className="fatigue">
        Fatigue
        <div className="gradient-slider">
          <div className="indicator"></div>
        </div>
        {value}
      </div>
    </>
  );
}

export default function Kennel() {
  const [showDetails, setShowDetails] = react.useState<boolean>(false);
  const [showNamingPopup, setShowNamingPopup] = react.useState<boolean>(false);
  const [showKennelSettings, setShowKennelSettings] =
    react.useState<boolean>(false);
  const [dataIndex, setDataIndex] = react.useState<number>(0);
  const [error, setError] = react.useState<string>("");

  const dispatch = useAppDispatch();
  const account = useAppSelector(selectAccount);
  const nonce = useAppSelector(selectNonce);
  const signature = useAppSelector(selectSignature);
  const allTokens = useAppSelector(selectAllTokens);
  const LottoMint1Tokens = useAppSelector(selectLottoMint1Tokens);
  const G1Tokens = useAppSelector(selectG1Tokens);
  const G2Tokens = useAppSelector(selectG2Tokens);
  const G3Tokens = useAppSelector(selectG3Tokens);
  const G4Tokens = useAppSelector(selectG4Tokens);

  const [filterString, setFilterString] = react.useState<string>("");

  const [context, setContext] = react.useState<TokenInfo[]>([]);
  const [detailFamily, setDetailFamily] = react.useState<string | undefined>();

  const testToggle = (index: number) => {
    setDataIndex(index);
    setShowDetails(!showDetails);
    window.scrollTo(0, 0);
  };

  const kennelInfo = useGetKennel(account.toLowerCase());

  const kennelUpdate = useUpdateKennel();

  const changeName = useChangeName();

  const updateKennelInfo = react.useCallback(async () => {
    //data for kennel is address and signature
    try {
      if (account && signature) {
        let data = {
          address: account,
          signature: signature,
        };

        kennelUpdate.mutate(data);
      }
    } catch (e: any) {
      console.log(e);
    }
  }, [account, signature]);
  react.useEffect(() => {
    updateKennelInfo();
    if (account) {
      kennelInfo.refetch();
    }
  }, [account, signature]);

  react.useEffect(() => {
    if (detailFamily === "LottoMint1") {
      setContext(LottoMint1Tokens!);
    } else if (detailFamily === "G1") {
      setContext(G1Tokens!);
    } else if (detailFamily === "G2") {
      setContext(G2Tokens!);
    } else if (detailFamily === "G3") {
      setContext(G3Tokens!);
    } else if (detailFamily === "G4") {
      setContext(G4Tokens!);
    }
  }, [detailFamily, LottoMint1Tokens, G1Tokens, G2Tokens, G3Tokens, G4Tokens]);

  const handleSubmitName = async (
    tokenFamily: string,
    tokenId: number,
    name: string
  ) => {
    if (name && name.length >= 4) {
      let sig = await signMessage(nonce);
      try {
        setError("");
        let data = {
          signature: sig,
          message: "Sign this to enter BETNFT.",
          address: account,
          ownerAddress: account,
          name: name,
          tokenId: tokenId,
          tokenFamily: tokenFamily,
        };
        let res = await changeName.mutateAsync(data);
        //const res = await axiosInstance.post("/tokens/rename", data);
        dispatch(updateTokenInfo(tokenFamily));
        setShowNamingPopup(false);
      } catch (e: any) {
        console.log(e, "Error");
        setError(e.response.data);
      }
    } else {
      setError("Name must be at least 4 characters long.");
    }
  };

  const handleSubmitKennelName = async (name: string, email: string) => {
    if (!email) {
      alert("Please enter an email address.");
      return;
    }
    if (name && name.length >= 4) {
      let sig = await signMessage(nonce);
      try {
        setError("");
        let data = {
          signature: sig,
          message: "Sign this to enter BETNFT.",
          address: account,
          ownerAddress: account,
          name: name,
          email: email,
        };
        await kennelUpdate.mutateAsync(data);

        setShowKennelSettings(false);
        updateKennelInfo();
      } catch (e: any) {
        console.log(e, "Error");
        setError(e.response.data);
      }
    } else {
      setError("Name must be at least 4 characters long.");
    }
  };

  const toggleNamingPopup = () => {
    setError("");
    setShowNamingPopup(!showNamingPopup);
  };

  const toggleSettingsPopup = () => {
    setError("");
    setShowKennelSettings(!showKennelSettings);
  };

  const [state, setState] = react.useState<SidebarState>({
    isOpen: false,
    bloodlineOpen: false,
    genderOpen: false,
    percentageOpen: false,
    bloodline: [],
    gender: [],
    percentage: [],
  });

  const toggleSidebar = (): void => {
    setState({ ...state, isOpen: !state.isOpen });
  };

  const [filterTokenData, setFilterTokenData] =
    React.useState<TokenInfo[]>(allTokens);

  const FilterDatas = (tokens: TokenInfo[]) => {
    let filteredTokens = [...allTokens];
    const findBloodline = (token: TokenInfo) => {
      let bloodlineAttrIndex = token.metadata?.attributes.findIndex(
        (attr) => attr.trait_type === "Bloodline"
      );
      return token.metadata?.attributes[bloodlineAttrIndex!].value;
    };

    const findGender = (token: TokenInfo) => {
      let genderAttrIndex = token.metadata?.attributes.findIndex(
        (attr) => attr.trait_type === "Gender"
      );
      return token.metadata?.attributes[genderAttrIndex!].value;
    };

    //filter for bloodline
    if (state.bloodline.length > 0) {
      filteredTokens = filteredTokens?.filter((item: TokenInfo) => {
        const bloodline = findBloodline(item);

        if (state.bloodline.includes(bloodline)) {
          return true;
        }

        return false;
      });
    }
    //filter for gender
    if (state.gender.length > 0) {
      filteredTokens = filteredTokens?.filter((item: TokenInfo) => {
        const gender = findGender(item);

        if (state.gender.includes(gender)) {
          return true;
        }

        return false;
      });
    }

    if (filterString === "") {
      return filteredTokens;
    }

    filteredTokens = filteredTokens.filter((item: TokenInfo) => {
      if (
        item.metadata?.name?.toLowerCase().includes(filterString.toLowerCase())
      ) {
        return true;
      }
      return false;
    });

    return filteredTokens;
  };

  React.useEffect(() => {
    let filterData = FilterDatas(allTokens);

    setFilterTokenData(filterData);
  }, [
    state.bloodline,
    state.gender,
    state.genderOpen,
    state.bloodlineOpen,
    allTokens,
    filterString,
  ]);

  return (
    <>
      {showNamingPopup && (
        <Popup>
          <Naming
            handleSubmitName={handleSubmitName}
            tokenData={filterTokenData[dataIndex]}
            error={error}
            handleClose={toggleNamingPopup}
            mutation={changeName}
          ></Naming>
        </Popup>
      )}
      {showKennelSettings && (
        <Popup>
          <KennelSettings
            mutation={kennelUpdate}
            handleSubmitName={handleSubmitKennelName}
            error={error}
            handleClose={toggleSettingsPopup}
          ></KennelSettings>
        </Popup>
      )}

      <div
        className="kennel-main"
        style={{
          position:
            showNamingPopup || showKennelSettings ? "fixed" : "relative",
        }}
      >
        <div className="top-tag dark-green">
          <span
            onClick={() => {
              setShowDetails(false);
              //setFilterString("");
            }}
          >
            OWNER'S KENNEL
          </span>
        </div>
        <div className="kennel-header ">
          {/* <img src={PlaceholderPic} className="picture"></img> */}
          {kennelInfo.data?.data.name
            ? kennelInfo.data.data.name
            : "Loading..."}
          <img src={Clipboard} className="clipboard" alt=""></img>
          <div
            className="settings"
            onClick={() => {
              if (signature) setShowKennelSettings(true);
            }}
          >
            <img src={Cog} alt=""></img>Settings
          </div>
        </div>
        <div className="kennel-content">
          {showDetails ? (
            <KennelDetail
              tokenData={filterTokenData[dataIndex]}
              submitName={handleSubmitName}
              toggleNamingPopup={toggleNamingPopup}
            ></KennelDetail>
          ) : (
            <>
              <div className="stat-header">
                <span>BLOODLINES</span>
                <span>CAREER STATS</span>
                <span>WIN PERCENTAGE</span>
                <span>PRIZE MONEY</span>
              </div>
              <div className="stat-info dark-green">
                <span>
                  {
                    NumberMap[
                      getBloodlineCount(
                        LottoMint1Tokens.concat(
                          G1Tokens.concat(
                            G2Tokens.concat(G3Tokens.concat(G4Tokens))
                          )
                        )
                      ).toString() as keyof typeof NumberMap
                    ]
                  }{" "}
                  (
                  {getBloodlineCount(
                    LottoMint1Tokens.concat(
                      G1Tokens.concat(
                        G2Tokens.concat(G3Tokens.concat(G4Tokens))
                      )
                    )
                  )}
                  )
                </span>
                <span>
                  {kennelInfo.data
                    ? `${kennelInfo.data?.data.career.firsts}/${kennelInfo.data?.data.career.seconds}/${kennelInfo.data?.data.career.thirds}`
                    : "Loading..."}
                </span>
                <span>
                  {kennelInfo.data
                    ? `${(kennelInfo.data?.data.completed_races !== 0
                        ? (kennelInfo.data?.data.career.firsts * 100) /
                          kennelInfo.data?.data.completed_races
                        : 0
                      ).toFixed(2)}%`
                    : "Loading..."}
                </span>
                <span>
                  {kennelInfo.data
                    ? `${token2DecimalDisplay(
                        kennelInfo.data?.data.new_total_earnings.USDT,
                        TokenType.USDT
                      )}`
                    : "Loading..."}
                </span>
              </div>
              <div className="holder">
                <div className="kennel-info">
                  <div className="kennel-info-header">GREYHOUND NAME</div>
                  <div className="filters dark-green">
                    <div className="container-box ">
                      <img
                        src={SearchIcon}
                        className="search-icon"
                        alt=""
                      ></img>
                      <input
                        type="text"
                        placeholder="SEARCH"
                        value={filterString}
                        onChange={(e) =>
                          setFilterString(e.target.value.toLowerCase())
                        }
                        // onChange={(e) => setFilterString(e.target.value)}
                      ></input>
                    </div>

                    {/* <div className="filter container-box ">
                      <img src={FilterIcon} className="filter-icon icon"></img>
                      Filters
                    </div> */}
                    {/* <div className="sort">
                      <span className="sort-by">SORT BY:</span>
                      <div className="container-box dark-green">
                        DATE- NEWEST{" "}
                        <img
                          src={DownArrow}
                          className="downarrow-icon icon"
                        ></img>
                      </div>
                    </div> */}
                    <div className="filter">
                      <div
                        className="container-box dark-green"
                        onClick={toggleSidebar}
                      >
                        Filters{" "}
                        <img
                          src={DownArrow}
                          className="downarrow-icon icon"
                          alt=""
                        ></img>
                      </div>
                    </div>
                  </div>
                  <div className="dogs">
                    {allTokens && (
                      <KennelList
                        filterTokenData={filterTokenData}
                        setFilterTokenData={setFilterTokenData}
                        tokenData={filterTokenData}
                        showDetails={testToggle}
                        filterString={filterString}
                      ></KennelList>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          <img src={LogoWShadow} className="logo-shadow" alt=""></img>
        </div>
      </div>
      {state.isOpen && (
        <>
          <Sidebar
            state={state}
            setState={setState}
            // Datarray={Datarray}
            filterTokenData={filterTokenData}
          />
        </>
      )}
    </>
  );
}
