import * as react from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ethers } from "ethers";
import { Race } from "../../../types";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import InfoWhite from "../../assets/info-green.svg";
import downArrow from "../../assets/down-arrow-green.svg";
import HighlightOff from "@mui/icons-material/HighlightOff";

import {
  calculatePrizeFromStructure,
  formatTokenAmount,
  getTimeToRace,
} from "../../../tools/helper";
import { selectAccount } from "../../../store/mainSlice";
import Switch from "@mui/material/Switch";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Menu, MenuItem } from "@mui/material";
import RaceInfoRow from "../../common/RaceInfoRow";
import { getRacePrizeStructureType } from "../../utils";
import { Box } from "@mui/system";
interface IProps {
  data: any;
  nextToRunData: any;
  isLoading?: boolean;
  isError?: boolean;
}

interface Position {
  x: number;
  y: number;
}

export default function Racelist(props: IProps) {
  const dispatch = useAppDispatch();
  const account = useAppSelector(selectAccount);
  const { data, nextToRunData } = props;
  const [timer, setTimer] = react.useState(0);
  const [races, setRaces] = react.useState<Race[]>([]);
  const [filter, setFilter] = react.useState("");
  const [filter_2, setFilter_2] = react.useState("");
  const [gatesFilter, setGatesFilter] = react.useState("");
  const [payoutsFilter, setPayoutsFilter] = react.useState("");
  const [entryFeeFilter, setEntryFeeFilter] = react.useState("");
  const [distanceFilter, setDistanceFilter] = react.useState("");
  const [showAll, setShowAll] = react.useState(true);
  const navigate = useNavigate();
  const [raceEnter, setRaceEnter] = react.useState<null | HTMLElement>(null); // main race to enter menu
  const [gatesMenu, setGatesMenu] = react.useState<null | HTMLElement>(null); // gates menu
  const [payoutsMenu, setPayoutsMenu] = react.useState<null | HTMLElement>(
    null
  ); // gates menu
  const [entryfeeMenu, setEntryfeeMenu] = react.useState<null | HTMLElement>(
    null
  ); // gates menu

  // filter menu
  const [distanceMenu, setDistanceMenu] = react.useState<null | HTMLElement>(
    null
  );

  const openRaceEnter = Boolean(raceEnter);
  const openGatesMenu = Boolean(gatesMenu);
  const openPayoutsMenu = Boolean(payoutsMenu);
  const openEntryfeeMenu = Boolean(entryfeeMenu);
  const openDistanceMenu = Boolean(distanceMenu);

  const handleRaceEnterMenu = (event: React.MouseEvent<HTMLElement>) => {
    setRaceEnter(event.currentTarget);
  };
  const handleGatesMenu = (event: React.MouseEvent<HTMLElement>) => {
    setGatesMenu(event.currentTarget);
  };
  const handlePayoutsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setPayoutsMenu(event.currentTarget);
  };
  const handleEntryfeeMenu = (event: React.MouseEvent<HTMLElement>) => {
    setEntryfeeMenu(event.currentTarget);
  };
  const handleDistFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
    setDistanceMenu(event.currentTarget);
  };

  const handleCloseRaceEnter = () => {
    setRaceEnter(null);
  };
  const handleCloseGatesMenu = () => {
    setGatesMenu(null);
    setRaceEnter(null);
  };
  const handleClosePayoutsMenu = () => {
    setPayoutsMenu(null);
    setRaceEnter(null);
  };
  const handleCloseEntryfeeMenu = () => {
    setEntryfeeMenu(null);
    setRaceEnter(null);
  };
  const handleCloseDistanceMenu = () => {
    setDistanceMenu(null);
    setRaceEnter(null);
  };
  const handleSelectGates = (gates: string) => {
    setGatesFilter(gates);
    handleCloseGatesMenu();
  };
  const handleSelectPayouts = (payouts: string) => {
    setPayoutsFilter(payouts);
    handleClosePayoutsMenu();
  };
  const handleSelectEntryFee = (entryFee: string) => {
    setEntryFeeFilter(entryFee);
    handleCloseEntryfeeMenu();
  };
  const handleSelectDistance = (distance: string) => {
    setDistanceFilter(distance);
    handleCloseDistanceMenu();
  };
  let count = 0;
  react.useEffect(() => {
    //re-render this component every second
    const interval = setInterval(() => {
      setTimer((prev) => prev + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  react.useEffect(() => {
    if (
      nextToRunData &&
      nextToRunData.data.length > 0 &&
      Array.isArray(nextToRunData.data)
    ) {
      //function to sort data by startTime property
      let copy = nextToRunData.data.filter((race: Race) => !race.cancelled);
      const sortData = (copy as Race[]).sort(function (a, b) {
        return a.startTime - b.startTime;
      });

      setRaces(sortData);
    }
  }, [nextToRunData]);

  const raceData = data?.data ? [...data?.data] : null;

  const handleFilter = (filter: string) => {
    if (filter === "All") {
      setFilter_2("");
      setFilter("");
      setGatesFilter("");
      setPayoutsFilter("");
      setEntryFeeFilter("");
      setDistanceFilter("");
      return;
    }
    setFilter(filter);
  };

  const handleCancel = () => {
    setFilter("");
    setFilter_2("");
    setGatesFilter("");
    setPayoutsFilter("");
    setEntryFeeFilter("");
    setDistanceFilter("");
  };

  const [hoveredRaces, setHoveredRaces] = react.useState<{
    [key: string]: boolean;
  }>({});

  const handleMouseEnter = (raceId: string) => {
    setHoveredRaces((prevState) => ({ ...prevState, [raceId]: true }));
  };

  const handleMouseLeave = (raceId: string) => {
    setHoveredRaces((prevState) => ({ ...prevState, [raceId]: false }));
  };

  return (
    <>
      <Tooltip id="my-tooltip" />
      <div className="racelist">
        <div className="topbar">
          <span className="sides">
            <button className="prev-btn sides-btn" onClick={() => navigate(-1)}>
              <img src="/left-arrow.svg" className="left-arrow" /> Back
            </button>
          </span>
          <div className="next">
            {/* TODO: Sort by time left */}
            {races.length > 0 ? (
              races.map((race: Race, index: number) => {
                if (
                  !race.completed ||
                  Date.now() < race.raceTime! * 1000 + race.startTime
                ) {
                  count += 1;
                  let countdown = getTimeToRace(race.startTime);
                  if (race.cancelled) {
                    return;
                  }

                  if (count <= 5)
                    return (
                      <Link
                        to={`/race/${race.raceId}`}
                        className="box"
                        key={race.raceId}
                      >
                        <div className="race-name">{race.raceName}</div>
                        <div className="time">
                          <span className="start">Starts:</span>
                          <span className="clock">
                            {Date.now() < race.startTime
                              ? `${
                                  countdown.hours ? countdown.hours + "h" : ""
                                } ${
                                  countdown.minutes
                                    ? countdown.minutes + "m"
                                    : ""
                                } ${
                                  countdown.seconds
                                    ? countdown.seconds + "s"
                                    : ""
                                }`
                              : "Live"}
                          </span>
                        </div>
                      </Link>
                    );
                }
              })
            ) : (
              <div className="none">
                {props.isLoading ? "Loading races..." : "No Races Found"}
              </div>
            )}
          </div>

          <span className="sides">See All</span>
        </div>
        <div className="race-classes">
          <div className="race-title-main">
            <div>
              <div className="title">
                RACES TO ENTER
                <img
                  src={downArrow}
                  className="down-arrow"
                  id="race-enter"
                  onClick={handleRaceEnterMenu}
                ></img>
                {/* race to enter menu  */}
                <div>
                  <Menu
                    id="race-enter-menu"
                    className="race-enter"
                    anchorEl={raceEnter}
                    open={openRaceEnter}
                    onClose={handleCloseRaceEnter}
                    MenuListProps={{
                      "aria-labelledby": "race-enter",
                    }}
                  >
                    <MenuItem
                      onClick={handleGatesMenu}
                      id="gates"
                      sx={{ color: "white" }}
                    >
                      Gates
                    </MenuItem>
                    <MenuItem
                      onClick={handlePayoutsMenu}
                      sx={{ color: "white" }}
                      id="payouts"
                    >
                      Payouts
                    </MenuItem>
                    <MenuItem
                      onClick={handleEntryfeeMenu}
                      sx={{ color: "white" }}
                      id="entry-fee"
                    >
                      Entry Fee
                    </MenuItem>
                    <MenuItem
                      onClick={handleDistFilterMenu}
                      sx={{ color: "white" }}
                      id="filters"
                    >
                      Distance
                    </MenuItem>
                  </Menu>
                </div>
                {/* gates menu  */}
                <Menu
                  id="gates-menu"
                  className="gates"
                  anchorEl={gatesMenu}
                  open={openGatesMenu}
                  onClose={handleCloseGatesMenu}
                  MenuListProps={{
                    "aria-labelledby": "gates",
                  }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem onClick={() => handleSelectGates("2")}>
                    Gates 2
                  </MenuItem>
                  <MenuItem onClick={() => handleSelectGates("3")}>
                    Gates 3
                  </MenuItem>
                  <MenuItem onClick={() => handleSelectGates("4")}>
                    Gates 4
                  </MenuItem>
                  <MenuItem onClick={() => handleSelectGates("5")}>
                    Gates 5
                  </MenuItem>
                  <MenuItem onClick={() => handleSelectGates("6")}>
                    Gates 6
                  </MenuItem>
                  <MenuItem onClick={() => handleSelectGates("7")}>
                    Gates 7
                  </MenuItem>
                  <MenuItem onClick={() => handleSelectGates("8")}>
                    Gates 8
                  </MenuItem>
                </Menu>
                {/* payouts menu  */}
                <Menu
                  id="payouts-menu"
                  className="payouts"
                  anchorEl={payoutsMenu}
                  open={openPayoutsMenu}
                  onClose={handleClosePayoutsMenu}
                  MenuListProps={{
                    "aria-labelledby": "payouts",
                  }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  {/* <MenuItem
                  onClick={handleClosePayoutsMenu}
                  sx={{
                    backgroundColor: "#2a9d8f",
                    ":hover": { backgroundColor: "#2a9d8f" },
                  }}
                >
                  1 V 1
                </MenuItem> */}
                  <MenuItem
                    onClick={() => handleSelectPayouts("WTA")}
                    sx={{
                      backgroundColor: "#2a9d8f",
                      ":hover": { backgroundColor: "#2a9d8f" },
                    }}
                  >
                    WTA
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleSelectPayouts("TOP 2")}
                    sx={{
                      backgroundColor: "#bc6c25",
                      ":hover": { backgroundColor: "#bc6c25" },
                    }}
                  >
                    TOP 2
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleSelectPayouts("TOP 3")}
                    sx={{
                      backgroundColor: "#669bbc",
                      ":hover": { backgroundColor: "#669bbc" },
                    }}
                  >
                    TOP 3
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleSelectPayouts("TOP 4")}
                    sx={{
                      backgroundColor: "#004e89",
                      ":hover": { backgroundColor: "#004e89" },
                    }}
                  >
                    TOP 4
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleSelectPayouts("LAST 3")}
                    sx={{
                      backgroundColor: "#9d4edd",
                      ":hover": { backgroundColor: "#9d4edd" },
                    }}
                  >
                    LAST 3
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleSelectPayouts("LTA")}
                    sx={{
                      backgroundColor: "#d1495b",
                      ":hover": { backgroundColor: "#d1495b" },
                    }}
                  >
                    LTA
                  </MenuItem>
                </Menu>
                {/* entry fee  */}
                <Menu
                  id="payouts-menu"
                  className="entry-fee"
                  anchorEl={entryfeeMenu}
                  open={openEntryfeeMenu}
                  onClose={handleCloseEntryfeeMenu}
                  MenuListProps={{
                    "aria-labelledby": "entry-fee",
                  }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem onClick={() => handleSelectEntryFee("Highest")}>
                    Highest
                  </MenuItem>
                  <MenuItem onClick={() => handleSelectEntryFee("Lowest")}>
                    Lowest
                  </MenuItem>
                </Menu>
                <Menu
                  id="payouts-menu"
                  className="payouts"
                  anchorEl={distanceMenu}
                  open={openDistanceMenu}
                  onClose={handleCloseDistanceMenu}
                  MenuListProps={{
                    "aria-labelledby": "entry-fee",
                  }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem
                    sx={{
                      backgroundColor: "#d1495b",
                      ":hover": { backgroundColor: "#d1495b" },
                    }}
                    onClick={() => handleSelectDistance("400")}
                  >
                    400m
                  </MenuItem>
                  <MenuItem
                    sx={{
                      backgroundColor: "#004e89",
                      ":hover": { backgroundColor: "#004e89" },
                    }}
                    onClick={() => handleSelectDistance("500")}
                  >
                    500m
                  </MenuItem>
                  <MenuItem
                    sx={{
                      backgroundColor: "#669bbc",
                      ":hover": { backgroundColor: "#669bbc" },
                    }}
                    onClick={() => handleSelectDistance("600")}
                  >
                    600m
                  </MenuItem>
                  <MenuItem
                    sx={{
                      backgroundColor: "#bc6c25",
                      ":hover": { backgroundColor: "#bc6c25" },
                    }}
                    onClick={() => handleSelectDistance("700")}
                  >
                    700m
                  </MenuItem>
                </Menu>
              </div>
            </div>
            <div className="classes">
              <div
                className="class-filter "
                onClick={() => handleFilter("All")}
              >
                All
              </div>
              <div
                className="class-filter "
                onClick={() => handleFilter("Chance")}
              >
                Chance{" "}
              </div>
              <div
                className="class-filter "
                onClick={() => handleFilter("Open")}
              >
                Open
              </div>
              <div className="class-filter " onClick={() => handleFilter("G1")}>
                Class G1
              </div>
              <div className="class-filter " onClick={() => handleFilter("G2")}>
                Class G2
              </div>
              <div className="class-filter " onClick={() => handleFilter("G3")}>
                Class G3
              </div>
              <div className="class-filter " onClick={() => handleFilter("G4")}>
                Class G4
              </div>
            </div>
            <div style={{ display: "flex", marginLeft: "auto" }}>
              <div
                className="class-filter "
                onClick={() => setFilter_2("Free")}
              >
                Free
              </div>
              <div
                className="class-filter "
                onClick={() => setFilter_2("Paid")}
              >
                Paid
              </div>
            </div>
            <div className="show-switch" style={{ marginLeft: "auto" }}>
              <span>{!showAll ? "Show My Dogs" : "Show All Dogs"}</span>
              <Switch
                onChange={() => {
                  setShowAll(!showAll);
                  console.log("switching");
                }}
              ></Switch>
            </div>
          </div>
          <div>
            <div className="filter-box">
              <div className="filter-label">
                {filter !== "" && (
                  <Box
                    className="filter-btn"
                    sx={{
                      background: "#2a9d8f",
                      cursor: "default",
                      marginRight: "10px",
                    }}
                  >
                    {filter}
                  </Box>
                )}
                {gatesFilter !== "" && (
                  <Box
                    className="filter-btn"
                    sx={{
                      background: "#2a9d8f",
                      cursor: "default",
                      marginRight: "10px",
                    }}
                  >
                    Gate {gatesFilter}
                  </Box>
                )}
                {payoutsFilter !== "" && (
                  <Box
                    className="filter-btn"
                    sx={{
                      background: "#2a9d8f",
                      cursor: "default",
                      marginRight: "10px",
                    }}
                  >
                    {payoutsFilter}
                  </Box>
                )}
                {entryFeeFilter !== "" && (
                  <Box
                    className="filter-btn"
                    sx={{
                      background: "#2a9d8f",
                      cursor: "default",
                      marginRight: "10px",
                    }}
                  >
                    {entryFeeFilter}
                  </Box>
                )}
                {distanceFilter !== "" && (
                  <Box
                    className="filter-btn"
                    sx={{
                      background: "#2a9d8f",
                      cursor: "default",
                      marginRight: "10px",
                    }}
                  >
                    {distanceFilter}m
                  </Box>
                )}
                {filter === "" &&
                  gatesFilter === "" &&
                  payoutsFilter === "" &&
                  entryFeeFilter === "" &&
                  distanceFilter === "" && (
                    <Box
                      className="filter-btn"
                      sx={{ background: "#2a9d8f", cursor: "default" }}
                    >
                      All
                    </Box>
                  )}
                {/* <Box
                    className="filter-btn"
                    sx={{ background: '#2a9d8f', cursor: 'default' }}
                  >
                    All
                  </Box> */}
              </div>
              {(filter !== "" ||
                gatesFilter !== "" ||
                payoutsFilter !== "" ||
                entryFeeFilter !== "" ||
                distanceFilter !== "") && (
                <div className="filter-label" onClick={handleCancel}>
                  <Box className="filter-btn cancel-btn">
                    Cancel
                    <HighlightOff
                      sx={{ marginLeft: "5px", fontSize: "17px" }}
                    />
                  </Box>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="list">
          <div className="titles race-event">
            <div>Race Event</div>
            <div>Event Type</div>
            <div>Distance</div>
            <div>Date</div>
            <div>Entry Fee</div>
            <div>
              Prize Pool
              <span
                onMouseEnter={() => handleMouseEnter("info")}
                onMouseLeave={() => handleMouseLeave("info")}
                className={`prize-pool grid-item info-tooltip relative ${
                  hoveredRaces["info"] ? "hovered" : ""
                }`}
              >
                <img src={InfoWhite} className="info" />
                {hoveredRaces["info"] && (
                  <div className="custom-tooltip info-tooltip-menu prize-pooltooltip">
                    <div className="innerdiv">
                      <span>
                        Calculated by the number of entrants less racing fee
                      </span>
                    </div>
                  </div>
                )}
              </span>
            </div>
            <div>Registered</div>
          </div>

          <div className="races race-event-races">
            {Array.isArray(raceData) ? (
              (raceData as Array<Race>)
                .sort((a, b) => {
                  if (entryFeeFilter) {
                    // Sort by entry fee which is a string
                    let aFee = a.entryFee || "0";
                    let bFee = b.entryFee || "0";
                    //parse with ethers to big number
                    let aFeeParsed = ethers.BigNumber.from(aFee);
                    let bFeeParsed = ethers.BigNumber.from(bFee);
                    if (entryFeeFilter === "Highest") {
                      // sort by highest entry fee
                      return bFeeParsed.lt(aFeeParsed) ? -1 : 1;
                    }
                    if (entryFeeFilter === "Lowest") {
                      // sort by lowest entry fee
                      return aFeeParsed.lt(bFeeParsed) ? -1 : 1;
                    }
                  }
                  // otherwise don't sort
                  return 1;
                })
                .filter((race: Race) => {
                  let condition1 = true;
                  let condition2 = true;
                  let gateCondition = true;
                  let payoutCondition = true;
                  let distCondition = true;
                  // Check for the first condition
                  if (filter) {
                    condition1 = race.raceClass === filter;
                  }

                  // Check for the second condition
                  if (filter_2) {
                    if (filter_2 === "Free") {
                      condition2 = !race.entryFee || race.entryFee === "0";
                    } else if (filter_2 === "Paid") {
                      condition2 = !!race.entryFee && race.entryFee !== "0";
                    }
                  }
                  if (gatesFilter) {
                    gateCondition =
                      (race.maxEntrants || 8) === parseInt(gatesFilter);
                  }
                  if (payoutsFilter) {
                    let payouttype: any = getRacePrizeStructureType(
                      race.prizePercentages
                    );
                    if (!payouttype) {
                      payoutCondition = false;
                    } else {
                      payoutCondition = payouttype[0] === payoutsFilter;
                    }
                  }

                  if (distanceFilter) {
                    distCondition =
                      race.raceDistance === parseInt(distanceFilter);
                  }
                  // Return true only if both conditions are satisfied
                  return (
                    condition1 &&
                    condition2 &&
                    gateCondition &&
                    payoutCondition &&
                    distCondition
                  );
                })
                .map((race: Race, index: number) => {
                  if (!showAll) {
                    if (
                      race.entrants.find((e) => e.ownerAddress === account) ===
                      undefined
                    )
                      return;
                  }
                  if (Date.now() < race.startTime && !race.cancelled) {
                    return <RaceInfoRow race={race} key={race._id} />;
                  }
                })
            ) : (
              <div className="none loading-data">
                {props.isLoading ? "Loading races..." : "No Races Found"}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

interface IndicatorProps {
  children?: react.ReactNode;
}
export function ClassIndicator(props: IndicatorProps) {
  return <div className="event-type">{props.children || "Open"}</div>;
}
