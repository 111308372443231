import * as react from "react";
import React, { useState } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { Box, Menu, MenuItem, Switch } from "@mui/material";
import downArrow from "../../../assets/down-arrow-green.svg";
import { HighlightOff } from "@mui/icons-material";
import { useAppSelector } from "../../../../app/hooks";
import { selectAccount } from "../../../../store/mainSlice";
import { CircularProgress } from "@mui/material";
import {
  useGetSnapshotHistory,
  useGetTokenWinsLeaderboard,
} from "../../../../services/queries";

const data = [{ name: "Greyhounds" }];

const bloodline = [
  { name: "G1", color: "#2a9d8f" },
  { name: "G2", color: "#bc6c25" },
  { name: "G3", color: "#669bbc" },
  { name: "G4", color: "#004e89" },
];

const periods = ["Weekly", "Monthly", "Yearly"];
const WinsLeaderBoards = () => {
  const [val, setval] = useState("Greyhounds");
  const [raceEnter, setRaceEnter] = react.useState<null | HTMLElement>(null); // main race to enter menu
  const [timeElement, setTimeElement] = react.useState<null | HTMLElement>(
    null
  ); // main
  const [snapshotElement, setSnapshotElement] =
    react.useState<null | HTMLElement>(null);
  let account = useAppSelector(selectAccount);
  const [paid, setPaid] = useState(false);
  const [greyhoundFilter, setGreyhoundFilter] = useState("");
  const [timeFilter, setTimeFilter] = useState("Monthly"); // Monthly, Weekly, Yearly
  const openRaceEnter = Boolean(raceEnter);
  const openTimeElement = Boolean(timeElement);
  const openSnapshotElement = Boolean(snapshotElement);

  const tokenLeaderboard = useGetTokenWinsLeaderboard(
    greyhoundFilter,
    timeFilter,
    paid
  );

  const snapshotHistory = useGetSnapshotHistory();

  const [showSnapshotLeaderboard, setShowSnapshotLeaderboard] = useState(false);
  const [snapshotData, setSnapshotData] = useState<any>(null);

  return (
    <div className="leaderboard-main">
      <div className="tab-main">
        <div className="tab-sec">
          {data.map((menu, i) => {
            return (
              <button
                className={`tab-btn ${val === menu.name && "active"}`}
                key={i}
                onClick={() => setval(menu.name)}
              >
                {menu.name}
              </button>
            );
          })}

          {val === "Greyhounds" && (
            <>
              <button className="tab-btn">
                Bloodline
                <img
                  src={downArrow}
                  className="down-arrow"
                  id="race-enter"
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    setRaceEnter(event.currentTarget)
                  }
                ></img>
                <Box
                  className="filter-btn"
                  sx={{
                    marginLeft: "15px",
                    background: "#2a9d8f",
                    cursor: "default",
                  }}
                >
                  {greyhoundFilter}
                </Box>
                {greyhoundFilter !== "" && (
                  <Box
                    className="filter-btn cancel-btn"
                    onClick={() => setGreyhoundFilter("")}
                  >
                    Cancel
                    <HighlightOff
                      sx={{ marginLeft: "5px", fontSize: "17px" }}
                    />
                  </Box>
                )}
              </button>
              <button className="tab-btn">
                Period - {timeFilter}
                <img
                  src={downArrow}
                  className="down-arrow"
                  id="race-enter"
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    setTimeElement(event.currentTarget)
                  }
                ></img>
              </button>
              {/* {account && (
                <>
                  <button className="tab-btn " style={{ marginLeft: "auto" }}>
                    {showAll ? "Show My Dogs" : "Show All Dogs"}
                    <Switch
                      value={showAll}
                      onClick={() => {
                        console.log("show all", showAll);
                        setShowAll(!showAll);
                      }}
                    ></Switch>
                  </button>
                </>
              )} */}
              <div>
                <Menu
                  id="race-enter-menu"
                  className="race-enter"
                  anchorEl={raceEnter}
                  open={openRaceEnter}
                  onClose={() => {
                    setRaceEnter(null);
                  }}
                  MenuListProps={{
                    "aria-labelledby": "race-enter",
                  }}
                >
                  {bloodline?.map((val, index) => {
                    return (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          setGreyhoundFilter(val.name);
                          setRaceEnter(null);
                        }}
                        sx={{
                          backgroundColor: val.color,
                          margin: "7px",
                          padding: "5px 20px",
                          borderRadius: "5px",
                          ":hover": { backgroundColor: val.color },
                        }}
                        id="gates"
                      >
                        {val.name}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </div>
              <div>
                <Menu
                  id="race-enter-menu"
                  className="race-enter"
                  anchorEl={timeElement}
                  open={openTimeElement}
                  onClose={() => {
                    setTimeElement(null);
                  }}
                  MenuListProps={{
                    "aria-labelledby": "race-enter",
                  }}
                >
                  {periods?.map((val, index) => {
                    return (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          setTimeFilter(val);
                          setTimeElement(null);
                        }}
                        id="gates"
                      >
                        {val}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </div>
              <div className="tab-btn">{paid ? "Paid" : "Free"}</div>
              <Switch
                value={paid}
                onClick={() => {
                  setPaid(!paid);
                }}
              ></Switch>
              <div
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {showSnapshotLeaderboard ? (
                  <>{snapshotData.snapshotName}</>
                ) : (
                  <></>
                )}
              </div>
              <button className="tab-btn">
                Snapshots
                <img
                  src={downArrow}
                  className="down-arrow"
                  id="race-enter"
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    setSnapshotElement(event.currentTarget)
                  }
                ></img>
              </button>
              {snapshotHistory.isSuccess && (
                <div>
                  <Menu
                    id="race-enter-menu"
                    className="race-enter"
                    anchorEl={snapshotElement}
                    open={openSnapshotElement}
                    onClose={() => {
                      setSnapshotElement(null);
                    }}
                    MenuListProps={{
                      "aria-labelledby": "race-enter",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        setSnapshotElement(null);
                        setSnapshotData(null);
                        setShowSnapshotLeaderboard(false);
                      }}
                      id="gates"
                    >
                      Current
                    </MenuItem>
                    {snapshotHistory.data.data?.map(
                      (val: any, index: number) => {
                        return (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              setSnapshotElement(null);
                              setSnapshotData(val);
                              setShowSnapshotLeaderboard(true);
                            }}
                            id="gates"
                          >
                            {val.snapshotName}
                          </MenuItem>
                        );
                      }
                    )}
                  </Menu>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div>
        <div className="list">
          <div className="titles race-result-titles summary-columns">
            <div>NAME</div>
            <div>Kennel</div>
            <div>WINS</div>
            {/* <div>PLACES</div>
            <div>PRIZE MONEY (USDT)</div> */}
          </div>
          {showSnapshotLeaderboard ? (
            <>
              <div className="races race-result-row">
                {snapshotData?.entrants.map((val: any, index: number) => {
                  let name = val.extraData.tokenName;

                  let wins = val.extraData.wins;
                  return (
                    <div
                      //to="/race/leaderboard"
                      key={index}
                      className="race-row summary-columns"
                    >
                      <div className="race-titles">
                        <span>
                          <span style={{ color: "yellow" }}>{index + 1})</span>{" "}
                          {name}
                        </span>
                      </div>
                      <div className="grid-item grid-places">{val.kennel}</div>
                      <div className="distance grid-item">{wins}</div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <div className="races race-result-row">
              {tokenLeaderboard?.data?.data.map((val: any, index: number) => {
                let name = val.name;

                let wins = val.wins;
                return (
                  <div
                    //to="/race/leaderboard"
                    key={index}
                    className="race-row summary-columns"
                  >
                    <div className="race-titles">
                      <span>
                        <span style={{ color: "yellow" }}>{index + 1})</span>{" "}
                        {name}
                      </span>
                    </div>
                    <div className="grid-item grid-places">
                      {val.kennelName}
                    </div>
                    <div className="distance grid-item">{wins}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WinsLeaderBoards;
