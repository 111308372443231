import { Box } from "@mui/material";
import { getRacePrizeStructureType } from "../../utils";
import * as react from "react";

interface IProps {
  prizePercentages: number[];
}
export default function PrizeTypeBadge({ prizePercentages }: IProps) {
  const prizeType = getRacePrizeStructureType(prizePercentages);
  const [isHovering, setIsHovering] = react.useState("");

  // handle case where prizeType is null, otherwise its tuple of [string, number]

  if (!prizeType) return null;

  let [prizeTypeString, index] = prizeType;

  let color = ColorMap[index as keyof typeof ColorMap];
  return (
    <>
      <div className="top-label-cover">
        <Box
          className="top-label"
          sx={{ background: color, cursor: "default" }}
          onMouseOver={() => {
            if (prizeTypeString === "WTA") {
              setIsHovering("WTA");
            }
            if (prizeTypeString === "LTA") {
              setIsHovering("LTA");
            }
          }}
          onMouseOut={() => setIsHovering("")}
        >
          {prizeTypeString}
        </Box>
      </div>
      {isHovering === "WTA" ? (
        <div className={`custom-tooltip prize-type-tooltip`}>
          <div className="innerdiv">
            <label style={{ color: "#ffffff" }}>
              <span style={{ color: "#2a9d8f", fontWeight: "800" }}>WTA</span> -
              Winner Takes All
            </label>
          </div>
        </div>
      ) : null}
      {isHovering === "LTA" ? (
        <div className={`custom-tooltip prize-type-tooltip`}>
          <div className="innerdiv">
            <label style={{ color: "#ffffff" }}>
              <span style={{ color: "#d1495b", fontWeight: "800" }}>LTA</span> -
              Last Takes All
            </label>
          </div>
        </div>
      ) : null}
    </>
  );
}

const ColorMap = {
  1: "#2a9d8f",
  2: "#bc6c25",
  3: "#669bbc",
  4: "#004e89",
  5: "#9d4edd",
  6: "#d1495b",
};
