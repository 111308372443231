import * as react from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import {
  useAllRaces,
  useCompletedRaces,
  useRaceWaiting,
} from "../../../services/queries";
import RaceList from "./racelist";
import RaceDetails from "./racedetails";
import History from "./history";
import ResultList from "./resultlist";
import { TournamentList, TournamentDetail } from "./tournaments";
import Menu from "../../common/Menu";
import { Race, TokenInfo } from "../../../types";
import "./style.scss";
import LeaderBoard from "./leaderboards";
import WinsLeaderBoards from "./leaderboards/WinsLeaderboard";

interface IProps {
  ownedTokens?: TokenInfo[];
}

const menuTabs: string[] = [
  "Races",
  "Tournaments",
  "Race Results",
  "My Race History",
  "Leaderboards",
];
const links: string[] = [
  "",
  "/tournaments",
  "/results",
  "/history",
  "/leaderboard",
];
export default function RaceContent({ ownedTokens }: IProps) {
  const [currentTab, setCurrentTab] = react.useState(0);

  const raceHistory = useCompletedRaces();
  const raceWaiting = useRaceWaiting();
  const [showRaceDetails, setShowRaceDetails] = react.useState(false);

  const handleTabChange = () => {
    setShowRaceDetails(false);
    //refetch();
    raceHistory.refetch();
    raceWaiting.refetch();
  };

  // react.useEffect(() => {
  //   if (pathname === "/race") refetch();
  // }, [pathname]);
  return (
    <>
      <Menu
        tabs={menuTabs}
        links={links}
        currentTab={currentTab}
        handleTabChange={handleTabChange}
      ></Menu>

      <Routes>
        <Route path="/:uuid" element={<RaceDetails></RaceDetails>}></Route>

        {/* <Route
          path="/results/:uuid"
          element={
            <ResultList
              data={data}
              nextToRunData={raceWaiting.data}
              isLoading={isFetching}
            />
          }
        ></Route> */}
        <Route
          path="/results"
          element={
            <ResultList
              nextToRunData={raceWaiting.data}
              data={raceHistory.data}
              isLoading={raceHistory.isFetching}
            />
          }
        ></Route>
        <Route
          path="/history"
          element={
            <History
              data={raceWaiting.data}
              isLoading={raceWaiting.isFetching}
            ></History>
          }
        ></Route>
        <Route
          path="/tournaments/:tournamentId"
          element={<TournamentDetail></TournamentDetail>}
        ></Route>
        <Route
          path="/tournaments"
          element={<TournamentList></TournamentList>}
        />
        <Route path="/leaderboard" element={<LeaderBoard></LeaderBoard>} />
        <Route
          path="/"
          element={
            <RaceList
              data={raceWaiting.data}
              nextToRunData={raceWaiting.data}
              isLoading={raceWaiting.isFetching}
            ></RaceList>
          }
        ></Route>
      </Routes>
    </>
  );
}
