import * as react from "react";
import "./style.scss";
import { useState } from "react";
import { ethers } from "ethers";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectAccount, selectSignature } from "../../store/mainSlice";
import { selectBalances, updateBalances } from "../../store/accountSlice";
import {
  Button,
  TextField,
  CircularProgress,
  Tab,
  Tabs,
  Pagination,
} from "@mui/material";
import {
  useDeposit,
  useWithdraw,
  useDepositHistory,
  useWithdrawalHistory,
  useInternalHistory,
} from "../../services/payment";
import {
  formatTokenAmount,
  formatTokenName,
  trimTransactionType,
} from "../../tools/helper";
import { TokenDecimals } from "../utils";
import { TokenType } from "../../types";

export default function Account() {
  const account = useAppSelector(selectAccount);
  const signature = useAppSelector(selectSignature);
  const balance = useAppSelector(selectBalances);
  const dispatch = useAppDispatch();

  const [tokenContext, setTokenContext] = useState<TokenType>(TokenType.USDT);

  const [tabValue, setTabValue] = useState<number>(0);

  const handleTabChange = (event: any, newValue: number) => {
    setTabValue(newValue);
  };

  // React states for deposit and withdrawal amounts
  const [depositAmount, setDepositAmount] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [page, setPage] = useState(1);

  const withdraw = useWithdraw();
  const deposit = useDeposit();
  const depositHistory = useDepositHistory({
    address: account,
    signature: signature,
    start: (page - 1) * 10,
    total: 10,
    onError: (e: any) => {},
    onSuccess: (data: any) => {
      setDepositHistory(data.transactions);
      setTotalDeposits(data.total);
    },
  });
  const withdrawHistory = useWithdrawalHistory({
    address: account,
    signature: signature,
    start: (page - 1) * 10,
    total: 10,
    onError: (e: any) => {},
    onSuccess: (data: any) => {
      setWithdrawHistory(data.transactions);
      setTotalWithdrawals(data.total);
    },
  });
  const internalHistory = useInternalHistory({
    address: account,
    signature: signature,
    start: (page - 1) * 10,
    total: 10,
    onError: (e: any) => {},
    onSuccess: (data: any) => {
      setInternalHistory(data.transactions);
      setTotalInternals(data.total);
    },
  });

  const [depositData, setDepositHistory] = useState<any>([]);
  const [totalDeposits, setTotalDeposits] = useState<any>(0);
  const [withdrawData, setWithdrawHistory] = useState<any>([]);
  const [totalWithdrawals, setTotalWithdrawals] = useState<any>(0);
  const [internalData, setInternalHistory] = useState<any>([]);
  const [totalInternals, setTotalInternals] = useState<any>(0);

  react.useEffect(() => {
    dispatch(updateBalances());
  }, [signature]);

  react.useEffect(() => {
    if (account && signature) {
      depositHistory.refetch();
      withdrawHistory.refetch();
      internalHistory.refetch();
    }
  }, [signature]);

  // Handlers for deposit and withdraw actions
  const handleDeposit = async () => {
    if (!depositAmount) return;
    setError("");
    setSuccess("");
    try {
      deposit.mutate(
        { amount: depositAmount, token: tokenContext },
        {
          onError: (e: any) => {
            console.log(e);
            setError(
              "Error depositing. Please check your balance and try again."
            );
          },
          onSuccess: () => {
            setTimeout(() => {
              setSuccess("Deposit successful");
              dispatch(updateBalances());
              depositHistory.refetch();
            }, 2000);
          },
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleWithdraw = async () => {
    if (!withdrawAmount) return;
    setError("");
    setSuccess("");
    let amount = ethers.utils.parseUnits(
      withdrawAmount,
      TokenDecimals[tokenContext]
    );
    withdraw.mutate(
      {
        amount: amount.toString(),
        signature: signature,
        address: account,
        token: tokenContext,
      },
      {
        onError: (e: any) => {
          if (e.response.status === 403) {
            setError("Invalid signature");
          } else {
            setError(e.response.data.message || "Unexpected error.");
          }
        },
        onSuccess: (data: any) => {
          setSuccess("Withdrawal successful");
          setTimeout(() => {
            dispatch(updateBalances());
            withdrawHistory.refetch();
          }, 3000);
        },
      }
    );
  };

  const handlePagination = (event: any, value: number) => {
    setPage(value);
  };

  react.useEffect(() => {
    if (!account) return;
    if (tabValue === 0) {
      depositHistory.refetch();
    }
    if (tabValue === 1) {
      withdrawHistory.refetch();
    }
    if (tabValue === 2) {
      internalHistory.refetch();
    }
  }, [page]);

  return (
    <>
      <div className="acc-container">
        <div className="title">Account Information</div>
        <div className="acc-info ">
          <span className=""> Current Address: </span>
          <span className="addr">{account}</span>
        </div>
        {account ? (
          <>
            <div className="token-info">
              <div>In-Game Balance</div>
              <div className="balances">
                <div
                  className="token-amount"
                  style={{
                    marginRight: "30px",
                  }}
                >
                  ${formatTokenAmount(balance.USDT, 6).toString()} - USDT
                </div>

                <div className="token-amount">
                  {formatTokenAmount(
                    balance.BETNFT,
                    TokenDecimals["BETNFT"]
                  ).toString()}{" "}
                  - $BetNFT
                </div>
              </div>
              <div className="balances"></div>
            </div>

            <div className="deposit-withdraw">
              <div className="input-container">
                <TextField
                  disabled={withdraw.isLoading || deposit.isLoading}
                  label="Deposit Amount"
                  value={depositAmount}
                  type="number"
                  onChange={(e) => {
                    setSuccess("");
                    setError("");
                    setDepositAmount(e.target.value);
                  }}
                  variant="outlined"
                  placeholder="0.0"
                  InputProps={{
                    style: { color: "white" },
                  }}
                  InputLabelProps={{
                    style: { color: "white" },
                  }}
                />
                <Button onClick={handleDeposit} color="warning">
                  Deposit
                </Button>
              </div>
              <div className="input-container">
                <TextField
                  disabled={withdraw.isLoading || deposit.isLoading}
                  label="Withdraw Amount"
                  color="info"
                  type="number"
                  inputProps={{ color: "white" }}
                  value={withdrawAmount}
                  placeholder="0.0"
                  onChange={(e) => {
                    setSuccess("");
                    setError("");
                    setWithdrawAmount(e.target.value);
                  }}
                  variant="outlined"
                  InputProps={{
                    style: { color: "white" },
                  }}
                  InputLabelProps={{
                    style: { color: "white" },
                  }}
                />
                <Button onClick={handleWithdraw} color="warning">
                  Withdraw
                </Button>
              </div>
            </div>
            <div>Current Token: {tokenContext}</div>
            <button onClick={() => setTokenContext(TokenType.USDT)}>
              Switch to USDT
            </button>
            <button onClick={() => setTokenContext(TokenType.BETNFT)}>
              Switch to $BetNFT
            </button>
            <div style={{ marginTop: "20px" }}>
              {withdraw.isLoading || deposit.isLoading ? (
                <>
                  <CircularProgress />
                </>
              ) : null}
              {success && <div className="success">{success}</div>}
              {error && <div className="error">{error}</div>}
            </div>
          </>
        ) : (
          <>
            <div className="connectwallet">Please connect your wallet</div>
          </>
        )}
        <div className="acc-history-wrapper">
          <div className="acc-history">
            <span> Account History</span>
            <div>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                textColor="inherit"
              >
                <Tab label="Deposits" />
                <Tab label="Withdraws" />
                <Tab label="In-Game" />
              </Tabs>
            </div>
            {tabValue === 0 && (
              <>
                <div className="table-wrapper">
                  <DepositTable data={depositData}></DepositTable>
                </div>
                <Pagination
                  count={Math.floor(totalDeposits / 10)}
                  page={page}
                  onChange={handlePagination}
                  style={{ marginTop: "20px" }}
                />
              </>
            )}
            {tabValue === 1 && (
              <>
                <div className="table-wrapper">
                  <WithdrawTable data={withdrawData}></WithdrawTable>
                </div>
                <Pagination
                  count={Math.floor(totalWithdrawals / 10)}
                  page={page}
                  onChange={handlePagination}
                  style={{ marginTop: "20px" }}
                />
              </>
            )}
            {tabValue === 2 && (
              <>
                <div className="table-wrapper">
                  <InternalTxTable data={internalData}></InternalTxTable>
                </div>
                <Pagination
                  count={Math.floor(totalInternals / 10)}
                  page={page}
                  onChange={handlePagination}
                  style={{ marginTop: "20px" }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const DepositTable = ({ data }: any) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Amount</th>
          <th>Token</th>
          <th>Transaction Hash</th>
        </tr>
      </thead>
      <tbody>
        {data.length === 0 && (
          <>
            <tr>
              <td colSpan={3} className="no-data">
                No Deposits Found
              </td>
            </tr>
          </>
        )}
        {data.map((tx: any) => {
          let tokenType = tx.token as TokenType;
          let displayName = formatTokenName(tokenType);
          return (
            <tr key={tx.txHash}>
              <td className="text-center">
                $
                {formatTokenAmount(
                  tx.amount,
                  TokenDecimals[tokenType]
                ).toString()}
              </td>
              <td className="text-center">{displayName}</td>
              <td className="text-center">
                {tx.txHash ? (
                  <a
                    href={`https://polygonscan.com/tx/${tx.txHash}`}
                    target="_blank"
                  >
                    {tx.txHash}
                  </a>
                ) : (
                  <>{tx.status}</>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const WithdrawTable = ({ data }: any) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Withdraw ID</th>
          <th>Amount</th>
          <th>Token</th>
          <th>Transaction Hash</th>
        </tr>
      </thead>
      <tbody>
        {data.length === 0 && (
          <>
            <tr>
              <td colSpan={4} className="no-data">
                No Withdrawals Found
              </td>
            </tr>
          </>
        )}
        {data.map((tx: any) => {
          let tokenType = tx.token as TokenType;
          let displayName = formatTokenName(tokenType);
          return (
            <tr key={tx.withdrawId}>
              <td className="text-center">{tx.withdrawId}</td>
              <td className="text-center">
                $
                {formatTokenAmount(
                  tx.amount,
                  TokenDecimals[tokenType]
                ).toString()}
              </td>
              <td className="text-center">{displayName}</td>
              <td className="text-center">
                {tx.txHash ? (
                  <a
                    href={`https://polygonscan.com/tx/${tx.txHash}`}
                    target="_blank"
                  >
                    {tx.txHash}
                  </a>
                ) : (
                  <>{tx.status}</>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const InternalTxTable = ({ data }: any) => {
  const getEventType = (type: string) => {
    if (type.includes("tournament-join")) {
      return "tournaments";
    }
    if (type.includes("race") || type.includes("tournament-payout")) {
      return "race";
    }
    return "internal";
  };

  let tokenType = data.token as TokenType;

  return (
    <table>
      <thead>
        <tr>
          <th>Id</th>
          <th>Amount</th>
          <th>Token</th>
          <th>Transaction Type</th>
          <th>Addtional Info</th>
        </tr>
      </thead>
      <tbody>
        {data.length === 0 && (
          <>
            <tr>
              <td colSpan={3} className="no-data ">
                No Internal Txs Found
              </td>
            </tr>
          </>
        )}
        {data.map((tx: any) => {
          let type = getEventType(tx.type);
          let prefix = type;
          if (type === "internal") {
            prefix = "";
          }
          let url = `/${prefix}/${tx.eventId}?showResult=false`;
          if (type === "tournaments") {
            url = `/race/tournaments/${tx.eventId}`;
          }

          let trimmedType = trimTransactionType(tx.type);
          let tokenType = tx.token as TokenType;
          let displayName = formatTokenName(tokenType);
          return (
            <>
              <tr key={tx.txId}>
                <td className="text-center">{tx.txId}</td>
                <td className="text-center">
                  $
                  {formatTokenAmount(
                    tx.amount,
                    TokenDecimals[tokenType]
                  ).toString()}
                </td>
                <td className="text-center">{displayName}</td>
                <td className="text-center">{trimmedType}</td>
                <td className="text-center">
                  {prefix ? (
                    <a href={`${url}`} target="_blank">
                      {tx.eventId}
                    </a>
                  ) : (
                    <>{tx.eventId}</>
                  )}
                </td>
              </tr>
            </>
          );
        })}
      </tbody>
    </table>
  );
};
