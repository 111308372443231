import { TokenDecimals } from "../components/utils";
import { TokenInfo, TokenType } from "../types";
import { ethers } from "ethers";

export function trimTransactionType(txString: string) {
  //Trim 'internal' from transaction type e.g 'internal-transaction' -> 'transaction'
  let txType = txString.replace("internal-", "");
  return txType;
}

export function formatTournamentFormat(format: string) {
  if (format === "sitngo") {
    return "Sit & Go";
  }
  if (format === "unlimited") {
    return "Regular";
  }
  return format;
}

export function calculatePrizeStructure(
  entryFee: string,
  entrants: number,
  prizePercentages: number[],
  prizePool?: string
) {
  let prizeStructure = prizePercentages.map((percentage) => {
    let fee = ethers.BigNumber.from(entryFee);
    let prize = fee.mul(entrants);

    let prizePercentage = prize.mul(percentage).div(100);
    return prizePercentage;
  });

  let totalPrize = prizeStructure.reduce(
    (a, b) => a.add(b),
    ethers.BigNumber.from(0)
  );
  return [prizeStructure, totalPrize];
}

export function calculatePrizeFromStructure(
  percentage: number,
  prizePool: string,
  tokenType: TokenType
) {
  // mulitply percentage by 100 to handle Big number calcs
  let percentageBN = ethers.BigNumber.from(percentage * 100);

  let totalPrize = ethers.BigNumber.from(prizePool);
  let prizePercentage = totalPrize.mul(percentageBN).div(100 * 100);

  return token2DecimalDisplay(prizePercentage.toString(), tokenType);
}

export function getTimeToRace(raceStartTime: number) {
  //unix timestamp only
  let diff = raceStartTime - Date.now();

  let seconds = Math.floor(diff / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);

  let timeToRace = {
    days: days,
    hours: hours,
    minutes: minutes % 60,
    seconds: seconds % 60,
  };
  return timeToRace;
}
export const sortArrayByKey = (array: any[], key?: string) => {
  return key
    ? array.sort((a: any, b: any) => {
        return a[key] - b[key];
      })
    : array.sort((a: any, b: any) => {
        return a - b;
      });
};

export const getBloodlineCount = (array: TokenInfo[]) => {
  //array of tokens, filter tokens by bloodline
  let bloodlines = array.map((token) => {
    let bloodlineAttrIndex = token.metadata?.attributes.findIndex(
      (attr) =>
        attr.trait_type === "Bloodline" || attr.trait_type === "bloodline"
    );
    return token.metadata?.attributes[bloodlineAttrIndex!].value;
  });
  return new Set(bloodlines).size;
};

export const countUniqueProperty = (array: any[], key: string) => {
  let unique = array.map((item) => item[key]);
  return new Set(unique).size;
};

export const token2DecimalDisplay = (amt: string, token: TokenType) => {
  const decimals = TokenDecimals[token];
  const amount = ethers.utils.formatUnits(amt, decimals);
  const parts = amount.split(".");
  let returnValue = amount;
  if (parts.length === 2) {
    // If there are more than 2 digits after the decimal, round it to 2 digits.
    if (parts[1].length > 2) {
      const floatAmount = parseFloat(amount);
      returnValue = floatAmount.toFixed(2);
      //return floatAmount.toFixed(2);
    }
    // If there are less than 2 digits after the decimal, add zeros until there are 2.
    else if (parts[1].length < 2) {
      parts[1] += "0".repeat(2 - parts[1].length);
      returnValue = parts.join(".");
      //return parts.join(".");
    }
  }

  if (token === "USDT") {
    return `$ ${returnValue}`;
  } else {
    return `${returnValue}`;
  }
};

export const formatTokenAmount = (amt: string, decimals: number) => {
  const amount = ethers.utils.formatUnits(amt, decimals);
  const parts = amount.split(".");

  // Ensuring minimum of 2 digits after the decimal point.
  if (parts.length === 2 && parts[1].length < 2) {
    parts[1] += "0".repeat(2 - parts[1].length);
  }

  return parts.join(".");
};

export const formatTokenName = (token: TokenType) => {
  switch (token) {
    case TokenType.USDT:
      return "USDT";
    case TokenType.BETNFT:
      return "$BetNFT";
    default:
      return token;
  }
};
