import * as react from "react";
import "./style.scss";
import No1 from "../../assets/racedogs/1.png";
import No2 from "../../assets/racedogs/2.png";
import No3 from "../../assets/racedogs/3.png";
import No4 from "../../assets/racedogs/4.png";
import No5 from "../../assets/racedogs/5.png";
import No6 from "../../assets/racedogs/6.png";
import No7 from "../../assets/racedogs/7.png";
import No8 from "../../assets/racedogs/8.png";

import Blue from "../../assets/racedogs/blue.png";
import Green from "../../assets/racedogs/green.png";
import Red from "../../assets/racedogs/red.png";
import Yellow from "../../assets/racedogs/yellow.png";
import White from "../../assets/racedogs/white.png";
import Black from "../../assets/racedogs/black.png";
import Pink from "../../assets/racedogs/pink.png";
import Stripe from "../../assets/racedogs/stripe.png";
import Body from "../../assets/racedogs/body.png";

interface IProps extends react.HTMLAttributes<HTMLDivElement> {
  color: string;
  number: number;
}

const colorMapping: { [key: string]: string } = {
  blue: Blue,
  green: Green,
  red: Red,
  yellow: Yellow,
  white: White,
  black: Black,
  pink: Pink,
  stripe: Stripe,
};
const numberMapping: { [key: number]: string } = {
  1: No1,
  2: No2,
  3: No3,
  4: No4,
  5: No5,
  6: No6,
  7: No7,
  8: No8,
};

export default function RaceDog({ color, number, ...props }: IProps) {
  return (
    <>
      <div className="race-dog" {...props}>
        <img src={Body} className="body"></img>
        <div className="vest">
          <img src={colorMapping[color]} className="color"></img>
          <img src={numberMapping[number]} className="number"></img>
        </div>
      </div>
    </>
  );
}
