import { ethers } from "ethers";
import { useQuery, useMutation, Query } from "@tanstack/react-query";
import BalanceContract from "../ABI/contract/Deposit.json";
import { axiosInstance } from "../config/config";
import ERC20 from "../ABI/contract/ERC20.json";
import { TokenType } from "../types";

export const USDT_ADDRESS = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";
// Function to deposit into the contract and get the token

const ADDRESS_MAP: {
  [key in TokenType]: {
    token_address: string;
    deposit_address: string;
  };
} = {
  USDT: {
    token_address: USDT_ADDRESS,
    deposit_address: process.env.REACT_APP_USDT_DEPOSIT_ADDRESS!,
  },
  BETNFT: {
    token_address: process.env.REACT_APP_BETNFT_ADDRESS!,
    deposit_address: process.env.REACT_APP_BETNFT_DEPOSIT_ADDRESS!,
  },
};

export const deposit = async (amount: string, token: TokenType) => {
  //parse the amount with ethers
  const DEPOSIT_ADDRESS = process.env.REACT_APP_USDT_DEPOSIT_ADDRESS!;

  const { token_address, deposit_address } = ADDRESS_MAP[token];

  try {
    const { ethereum } = window as any;
    if (ethereum) {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();

      //First approve the contract to spend the amount
      const TokenContract = new ethers.Contract(token_address, ERC20, signer);
      //Check the approval if it is already approved to be spent
      const allowance = await TokenContract.allowance(
        signer.getAddress(),
        deposit_address
      );
      //Token decimals
      const decimals = await TokenContract.decimals();

      //allowance as bignumber
      const parsedAmount = ethers.utils.parseUnits(amount, decimals);
      console.log("Allowance: ", allowance.toString());
      console.log("parsedAmount: ", parsedAmount.toString());
      //If the allowance is less than the amount to be spent, then approve the contract
      if ((allowance as ethers.BigNumber).lt(parsedAmount)) {
        let approval = await TokenContract.approve(
          deposit_address,
          parsedAmount
        );

        //Wait for the approval to be mined
        await approval.wait(5);
      }

      const DepositContract = new ethers.Contract(
        deposit_address,
        BalanceContract.abi,
        signer
      );
      let tx = await DepositContract.deposit(parsedAmount);
      await tx.wait(5);
      return tx;
    } else {
      alert("Please install Metamask");
    }
  } catch (error: any) {
    throw error;
  }
};

export const useDeposit = () => {
  return useMutation(
    ["deposit"],
    ({ amount, token }: { amount: string; token: TokenType }) => {
      return deposit(amount, token);
    }
  );
};

export const useWithdraw = () => {
  return useMutation(["withdrawal"], (data: any) => {
    return axiosInstance.post("/accounts/withdraw", data);
  });
};

export interface QueryParams {
  address: string;
  signature: string;
  start?: number;
  total?: number;
  onError: (error: any) => void;
  onSuccess: (data: any) => void;
}

export const useDepositHistory = ({
  address,
  signature,
  start = 0,
  total = 10,
  onError,
  onSuccess,
}: QueryParams) => {
  return useQuery(
    ["deposit-history", address, signature], // Add the address and signature to the query key
    async () => {
      const response = await axiosInstance.get("/accounts/history", {
        params: {
          address: address,
          signature: signature,
          transactionType: "deposit",
          start: start,
          total: total,
        },
      });

      return {
        transactions: response.data.transactions,
        total: response.data.total,
      }; // Ensure you return the data from the response
    },
    {
      onError: onError,
      onSuccess: onSuccess,
      // Add any additional query configuration options here, e.g.:
      refetchOnWindowFocus: false,
      refetchInterval: 10000,
      enabled: false,
      // staleTime: 60000, // 1 minute
    }
  );
};

export const useWithdrawalHistory = ({
  address,
  signature,
  start = 0,
  total = 10,
  onSuccess,
  onError,
}: QueryParams) => {
  return useQuery(
    ["withdrawal-history", address, signature], // Add the address and signature to the query key
    async () => {
      const response = await axiosInstance.get("/accounts/history", {
        params: {
          address: address,
          signature: signature,
          transactionType: "withdraw",
          start: start,
          total: total,
        },
      });

      return {
        transactions: response.data.transactions,
        total: response.data.total,
      }; // Ensure you return the data from the response
    },
    {
      onError: onError,
      onSuccess: onSuccess,
      // Add any additional query configuration options here, e.g.:
      refetchOnWindowFocus: false,
      refetchInterval: 10000,
      enabled: false,
      // staleTime: 60000, // 1 minute
    }
  );
};

export const useInternalHistory = ({
  address,
  signature,
  start = 0,
  total = 10,
  onSuccess,
  onError,
}: QueryParams) => {
  return useQuery(
    ["internal-history", address, signature], // Add the address and signature to the query key
    async () => {
      const response = await axiosInstance.get("/accounts/history", {
        params: {
          address: address,
          signature: signature,
          transactionType: "internal",
          start: start,
          total: total,
        },
      });

      return {
        transactions: response.data.transactions,
        total: response.data.total,
      }; // Ensure you return the data from the response
    },
    {
      onError: onError,
      onSuccess: onSuccess,
      // Add any additional query configuration options here, e.g.:
      refetchOnWindowFocus: false,
      refetchInterval: 100000,
      enabled: false,
      // staleTime: 60000, // 1 minute
    }
  );
};
