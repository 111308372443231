import "./App.css";
import * as react from "react";
import { Provider } from "react-redux";
import { store } from "./app/store";
import Main from "./components/main";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

function App() {
  return (
    <>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Main></Main>
        </LocalizationProvider>
      </Provider>
    </>
  );
}

export default App;
