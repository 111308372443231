import * as react from "react";
import { CircularProgress } from "@mui/material";
import { Tournament, TokenInfo, TokenType } from "../../../../types";
import { getTokenImage } from "../../../../services/queries";
import { UseMutationResult } from "@tanstack/react-query";
import LogoWShadow from "../../../assets/logowshadow.png";
import { trimBloodlineAttribute } from "../../kennel";
import { useAppSelector } from "../../../../app/hooks";
import { selectBalances } from "../../../../store/accountSlice";
import { ethers } from "ethers";
import { Link } from "react-router-dom";
import {
  selectLottoMint1Tokens,
  selectG1Tokens,
  selectG2Tokens,
  selectG3Tokens,
  selectG4Tokens,
  selectAllTokens,
} from "../../../../store/mainSlice";
import {
  formatTokenAmount,
  formatTokenName,
  token2DecimalDisplay,
} from "../../../../tools/helper";
import { TokenDecimals } from "../../../utils";

interface IProps {
  handleJoin: (tokenFamily: string, tokenId: number) => Promise<void>;
  handleReset: () => void;
  handleClose: () => void;
  tData: Tournament;
  joinStatus: UseMutationResult;
  children?: react.ReactNode;
  error?: string;
}

export default function NameToken(props: IProps) {
  const [showConfirmScreen, setShowConfirmScreen] = react.useState(false);
  const [selectedToken, setSelectedToken] = react.useState<TokenInfo | null>(
    null
  );
  const balances = useAppSelector(selectBalances);
  const allTokens = useAppSelector(selectAllTokens);
  const LottoMint1Tokens = useAppSelector(selectLottoMint1Tokens);
  const G1Tokens = useAppSelector(selectG1Tokens);
  const G2Tokens = useAppSelector(selectG2Tokens);
  const G3Tokens = useAppSelector(selectG3Tokens);
  const G4Tokens = useAppSelector(selectG4Tokens);

  const joinStatus = props.joinStatus;
  //TODO: filter by allowed tokens such as the tokenFamilies allowed or race classes etc

  const [allowedTokens, setAllowedTokens] = react.useState<TokenInfo[]>([]);

  react.useEffect(() => {
    let tokens: TokenInfo[] = [];
    allTokens.map((token) => {
      let bloodlineAttr = token.metadata?.attributes.findIndex(
        (attr) => attr.trait_type === "Bloodline"
      );
      let [bloodlineTag, bloodlineName] = trimBloodlineAttribute(
        token.metadata!.attributes[bloodlineAttr!].value
      );
      if (
        props.tData.t_class !== "Open" &&
        props.tData.t_class !== "Chance" &&
        props.tData.t_class !== bloodlineTag
      )
        return;
      if (
        props.tData.entrants.findIndex(
          (t) =>
            t.tokenId === token.tokenId && t.tokenFamily === token.tokenFamily
        ) === -1
      ) {
        tokens.push(token);
      }
    });
    setAllowedTokens(tokens);
  }, [props.tData, props.joinStatus]);

  const fee_token = props.tData.fee_token
    ? props.tData.fee_token
    : TokenType.USDT;

  const tokenDisplayName = formatTokenName(fee_token);

  return (
    <>
      <div className="bg">
        {!showConfirmScreen && (
          <>
            <div className="close" onClick={() => props.handleClose()}>
              X
            </div>
            <div className="container">
              <div className="title">Join a Tournament</div>
              <div className="subtitle">
                {props.tData.name} at {props.tData.location || "BetNFT"}
              </div>
              <div className="amounts">
                <span className="text-green">Prize Pool:</span>
                {token2DecimalDisplay(props.tData.prizePool, fee_token)}{" "}
                {tokenDisplayName}
              </div>
              <div className="amounts">
                <span className="text-green">Entry Fee:</span>
                {token2DecimalDisplay(props.tData.entryFee, fee_token)}{" "}
                {tokenDisplayName}
              </div>
              <div className="amounts">
                <span className="text-green">In-Game Balance:</span>
                {token2DecimalDisplay(balances[fee_token], fee_token)}{" "}
                {tokenDisplayName}
              </div>
              <div className="amounts text-yellow text-center">
                Only your eligible dogs for this race will be shown
              </div>
              <div className="tokens">
                {allowedTokens.map((token, index) => {
                  let bloodlineAttr = token.metadata?.attributes.findIndex(
                    (attr) => attr.trait_type === "Bloodline"
                  );
                  let [bloodlineTag, bloodlineName] = trimBloodlineAttribute(
                    token.metadata!.attributes[bloodlineAttr!].value
                  );

                  if (
                    props.tData.entrants.findIndex(
                      (t) =>
                        t.tokenId === token.tokenId &&
                        t.tokenFamily === token.tokenFamily
                    ) === -1
                  )
                    return (
                      <div
                        className="row-popup"
                        key={token.tokenFamily + token.tokenId}
                      >
                        <div className="dog">
                          <div className="img-container">
                            <img
                              src={getTokenImage(token.metadata?.image!)}
                              className="join-dog"
                            ></img>
                          </div>

                          {/* <img src={DividerTest} className="divider"></img> */}
                          <div className="name-type">
                            <div className="name">{token.metadata?.name}</div>
                            <div className="type">
                              {bloodlineTag + " - " + bloodlineName}
                            </div>
                          </div>
                          <button
                            disabled={joinStatus.isLoading}
                            className="join-button"
                            onClick={() => {
                              props.handleReset();
                              setSelectedToken(token);
                              setShowConfirmScreen(true);
                            }}
                          >
                            Join
                          </button>
                        </div>
                      </div>
                    );
                })}
              </div>
              {allowedTokens.length < 1 && (
                <div className="no-tokens-text">
                  <span>
                    Sorry, but it looks like you don’t have any dogs for this
                    race 😞.
                  </span>
                  <span>
                    Please make sure your wallet is connected and visit your
                    <Link to="/kennel">kennel</Link>or
                    <a href="https://mint.betnft.run" target="_blank">
                      buy a valid
                    </a>
                    dog 🙂.
                  </span>
                </div>
              )}
            </div>
            <img src={LogoWShadow} alt="logo" className="logo" />
          </>
        )}
        {showConfirmScreen && (
          <>
            <ConfirmScreen
              key={selectedToken?.tokenId! + selectedToken?.tokenFamily! || 0}
              handleJoin={props.handleJoin}
              tData={props.tData}
              tokenData={selectedToken!}
              joinStatus={joinStatus}
              handleBack={() => {
                setSelectedToken(null);
                setShowConfirmScreen(false);
              }}
              error={props.error}
            ></ConfirmScreen>
          </>
        )}
      </div>
    </>
  );
}

interface ConfirmProps {
  handleJoin: (tokenFamily: string, tokenId: number) => Promise<void>;
  handleBack: () => void;
  tData: Tournament;
  joinStatus: UseMutationResult;
  tokenData: TokenInfo;
  children?: react.ReactNode;
  error?: string;
}

const ConfirmScreen = ({
  tokenData,
  handleJoin,
  handleBack,
  joinStatus,
  tData,
  error,
}: ConfirmProps) => {
  let bloodlineAttr = tokenData.metadata?.attributes.findIndex(
    (attr) => attr.trait_type === "Bloodline"
  );
  let [bloodlineTag, bloodlineName] = trimBloodlineAttribute(
    tokenData.metadata!.attributes[bloodlineAttr!].value
  );

  const [disableButton, setDisableButton] = react.useState(false);

  react.useEffect(() => {
    if (joinStatus.isIdle) {
      setDisableButton(false);
    }
  }, [joinStatus.isSuccess]);
  const fee_token = tData.fee_token ? tData.fee_token : TokenType.USDT;
  const tokenDisplayName = formatTokenName(fee_token);

  return (
    <>
      <div className="confirm-screen">
        <div onClick={() => handleBack()} className="back-button">
          {"<"}
        </div>
        {joinStatus.isError && (
          <>
            <div className="center">
              {error || "Unexpected error, please try again later"}
            </div>
          </>
        )}
        {joinStatus.isIdle && (
          <>
            <div className="center">{tData.name}</div>
            <div className="center">
              Entry fee - {token2DecimalDisplay(tData.entryFee, fee_token)}{" "}
              {tokenDisplayName}
            </div>
            <div className="center">{tokenData.metadata?.name}</div>
            <div className="center">
              {bloodlineTag} - {bloodlineName}
            </div>
            <div className="dog center">
              <div className="token-image">
                <img
                  src={getTokenImage(tokenData.metadata?.image!)}
                  className="join-dog"
                ></img>
              </div>
            </div>
            <div className="center">
              <button
                className="confirm-button"
                disabled={disableButton}
                onClick={() => {
                  setDisableButton(true);
                  handleJoin(tokenData.tokenFamily, tokenData.tokenId);
                }}
              >
                Confirm
              </button>
            </div>
          </>
        )}
        {joinStatus.isSuccess && (
          <>
            <div className="center">Joined Successfully!</div>
          </>
        )}

        {joinStatus.isLoading && (
          <div className="center">
            <CircularProgress />
          </div>
        )}
      </div>
    </>
  );
};
