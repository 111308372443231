import * as react from "react";
import { useParams } from "react-router-dom";
import Switch from "@mui/material/Switch";
import moment from "moment";
import SearchIcon from "../../../assets/search.svg";
import { useAppSelector } from "../../../../app/hooks";
import { selectAccount, selectAllTokens } from "../../../../store/mainSlice";
import { Race } from "../../../../types";
import { Routes, Route, Link } from "react-router-dom";
import InfoWhite from "../../../assets/info-white.png";
import { OrdinalMap } from "../../../utils";
import { getTimeToRace } from "../../../../tools/helper";
import { useUserHistory } from "../../../../services/queries";
import "./style.scss";
interface IProps {
  data: any;

  isLoading?: boolean;
  isError?: boolean;
}

export default function UserHistory(props: IProps) {
  const userAddress = useAppSelector(selectAccount);
  const allTokens = useAppSelector(selectAllTokens);
  const { data: historyData } = useUserHistory(userAddress);

  const [filterString, setFilterString] = react.useState("");
  const [showAll, setShowAll] = react.useState(false);

  const [showSummary, setShowSummary] = react.useState(false);
  const [races, setRaces] = react.useState<Race[]>([]);
  const { data } = props;
  const { uuid } = useParams(); //RaceID

  react.useEffect(() => {
    if (uuid) {
      setShowSummary(true);
    } else {
      setShowSummary(false);
    }
  }, [uuid]);
  const [timer, setTimer] = react.useState(0);
  react.useEffect(() => {
    //re-render this component every second
    const interval = setInterval(() => {
      setTimer((prev) => prev + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  react.useEffect(() => {
    if (data && data.data.length > 0 && Array.isArray(data.data)) {
      //function to sort data by startTime property
      let copy = data.data.filter((race: Race) => !race.cancelled);
      const sortData = (copy as Race[]).sort(function (a, b) {
        return a.startTime - b.startTime;
      });

      setRaces(sortData);
    }
  }, [data]);

  let count = 0;
  return (
    <>
      <div className="racelist">
        <div className="topbar">
          <span className="sides">Next to Run</span>
          <div className="next">
            {/* TODO: Sort by time left */}
            {races.length > 0 ? (
              races.map((race: Race, index: number) => {
                if (
                  !race.completed ||
                  Date.now() < race.raceTime! * 1000 + race.startTime
                ) {
                  count += 1;
                  let countdown = getTimeToRace(race.startTime);
                  if (race.cancelled) {
                    return;
                  }
                  if (count <= 5)
                    return (
                      <Link
                        to={`/race/${race.raceId}`}
                        className="box"
                        key={race.raceId}
                      >
                        <div className="race-name">{race.raceName}</div>
                        <div className="time">
                          <span className="start">Starts:</span>
                          <span className="clock">
                            {Date.now() < race.startTime
                              ? `${
                                  countdown.hours ? countdown.hours + "h" : ""
                                } ${
                                  countdown.minutes
                                    ? countdown.minutes + "m"
                                    : ""
                                } ${
                                  countdown.seconds
                                    ? countdown.seconds + "s"
                                    : ""
                                }`
                              : "Live"}
                          </span>
                        </div>
                      </Link>
                    );
                }
              })
            ) : (
              <div className="none loading-data">
                {props.isLoading ? "Loading races..." : "No Races Found"}
              </div>
            )}
          </div>
          <span className="sides">See All</span>
        </div>
        <div
          className="race-classes race-history-classes"
          style={{ justifyContent: "space-between" }}
        >
          <div className="title race-hostory">
            <div className="history">
              <p>MY RACING HISTORY</p>
              <img src={InfoWhite} alt='infowhite'></img>
            </div>
            <div className="search-results ">
              <img src={SearchIcon} className="search-icon" alt='SearchIcon'></img>
              <input
                type="text"
                placeholder="SEARCH"
                onChange={(e) => setFilterString(e.target.value)}
              ></input>
            </div>
          </div>
          <div className="outcome-switch">
            <span className="switch-msg">
              {showAll ? "Hide Outcomes" : "Show Outcomes"}
            </span>
            <Switch onChange={() => setShowAll(!showAll)}></Switch>
          </div>
        </div>

        <div className="history-list">
          <div className="titles summary-columns">
            <div>PLACE</div>
            <div>GREYHOUND</div>
            <div>RACE NAME</div>
            <div>DISTANCE</div>
            <div>DATE</div>
          </div>

          {!showSummary && (
            <>
              <div className="race-history">
                {Array.isArray(historyData?.data) ? (
                  historyData?.data.map((race: Race, index: number) => {
                    return race.entrants.map((entrant: any, index2: any) => {
                      console.log(entrant, "entrant");

                      if (entrant.ownerAddress === userAddress) {
                        let outcomePosition =
                          race.outcome?.findIndex(
                            (item: any) =>
                              item.tokenId === entrant.tokenId &&
                              item.tokenFamily === entrant.tokenFamily
                          )! + 1;
                        let token = allTokens.find(
                          (item) =>
                            item.tokenId === entrant.tokenId &&
                            item.tokenFamily === entrant.tokenFamily
                        );

                        if (race.cancelled) {
                          outcomePosition = 0;
                        }

                        const match =
                          token
                            ?.metadata!.name.toLowerCase()
                            .substring(0, filterString.length) ===
                          filterString.toLowerCase();

                        return (
                          match && (
                            <Link
                              to={`/race/${race.raceId}`}
                              className="history-row"
                              key={race.raceId + index2}
                              onClick={() => {
                                window.scrollTo(0, 0);
                              }}
                            >
                              <div className="grid-item ">
                                <div className="position">
                                  {showAll
                                    ? outcomePosition
                                      ? OrdinalMap[
                                          outcomePosition as keyof typeof OrdinalMap
                                        ]
                                      : "A"
                                    : outcomePosition
                                    ? "?"
                                    : "A"}
                                </div>
                              </div>
                              <div className="name grid-item">
                                {token?.metadata!.name}
                              </div>
                              <div className="race-titles">
                                <span>
                                  {race.raceName}
                                  {race.cancelled && " - Abandoned"}
                                </span>
                              </div>

                              <div className="distance grid-item">
                                {race.raceDistance}m
                              </div>
                              <div className="date grid-item">
                                {moment(race.startTime).format(
                                  "DD MMM, YYYY. hh:mm"
                                )}
                              </div>
                            </Link>
                          )
                        );
                      }
                    });
                  })
                ) : (
                  <div className="none">
                    {props.isLoading ? "Loading races..." : "No Races Found"}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
