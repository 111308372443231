import * as react from "react";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import {
  selectAccount,
  selectSignature,
  selectLottoMint1Tokens,
  selectG1Tokens,
  selectG2Tokens,
  selectG3Tokens,
  selectG4Tokens,
  setAccount,
  setSignature,
  setLottoMint1Tokens,
  setG1Tokens,
  setG2Tokens,
  setG3Tokens,
  setG4Tokens,
  setNonce,
  selectNonce,
} from "../store/mainSlice";
import Navbar from "./navbar";
import Content from "./content";
import Footer from "./common/Footer";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import { axiosInstance } from "../config/config";
import { connectWallet, signMessage } from "../tools/wallet";
import { getCurrentUser } from "./../services/auth";
import { User, TokenInfo } from "./../types";
import { getTokensOwned } from "./../services/queries";
import { updateBalances } from "../store/accountSlice";

let websocket = new WebSocket(process.env.REACT_APP_WSS_URL!);
export const themeOptions = {};

const theme = createTheme(themeOptions);

export const AccountContext = react.createContext<User | undefined>(undefined);

export default function Main() {
  const dispatch = useAppDispatch();
  const account = useAppSelector(selectAccount);
  const signature = useAppSelector(selectSignature);
  const nonce = useAppSelector(selectNonce);
  const [currentUser, setCurrentUser] = react.useState<User | undefined>(
    undefined
  );

  const [currentNetwork, setCurrentNetwork] = react.useState("");
  const [error, setError] = react.useState("");

  websocket.onopen = () => {};
  websocket.onmessage = async (evt) => {
    if (evt.data) {
      let data = JSON.parse(evt.data);

      if (data.nonce) {
        dispatch(setNonce(data.nonce));
        // setTimeout(async () => {
        //   const signature = await signMessage(data.nonce);
        //   dispatch(setSignature(signature));
        // }, 1000);
      }
    }
  };
  websocket.onerror = (evt) => {};
  websocket.onclose = (evt) => {};

  const checkIfWalletIsConnected = react.useCallback(async () => {
    /*
     * First make sure we have access to window.ethereum
     */
    const { ethereum } = window as any;

    if (!ethereum) {
      return;
    }

    await ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: "0x89",
          chainName: "Polygon",
          rpcUrls: ["https://polygon-rpc.com/"],
        },
      ],
    });

    const accounts = await ethereum.request({ method: "eth_accounts" });

    /*
     * User can have multiple authorized accounts, we grab the first one if its there!
     */
    if (accounts.length !== 0) {
      const account = accounts[0];

      dispatch(setAccount(account));
      setCurrentNetwork("0x89");

      let chainId = await ethereum.request({ method: "eth_chainId" });
    } else {
    }
  }, [account]);
  // react.useEffect(() => {
  //   checkIfWalletIsConnected();
  // }, []);

  const dispatchWrapper = (address: string) => {
    dispatch(setAccount(address));
  };

  const connectAndSign = async () => {
    let address = await connectWallet(
      currentNetwork,
      dispatchWrapper,
      setCurrentNetwork,
      setError
    );

    const signature = await signMessage(nonce);
    dispatch(setSignature(signature!));
    try {
      await updateAll(address);
    } catch (e) {
      console.log(e);
    }

    //load user dogs after signature
  };

  const updateAll = async (address: string) => {
    console.log("update all");
    let success = await Promise.all([
      await getTokensOwned(address, "LottoMint1"),
      await getTokensOwned(address, "G1"),
      await getTokensOwned(address, "G2"),
      await getTokensOwned(address, "G3"),
      await getTokensOwned(address, "G4"),
    ]);
    if (success) {
      console.log("success");
      dispatch(setLottoMint1Tokens(success[0]));
      dispatch(setG1Tokens(success[1]));
      dispatch(setG2Tokens(success[2]));
      dispatch(setG3Tokens(success[3]));
      dispatch(setG4Tokens(success[4]));
    }
  };

  const loadTokens = react.useCallback(
    async (address: string) => {
      if (address) {
        try {
          await updateAll(address);
        } catch (e) {
          console.log(e);
        }
      }
    },
    [account]
  );

  react.useEffect(() => {
    if (account === "") return;
    loadTokens(account);
    dispatch(updateBalances());
  }, [account]);

  react.useEffect(() => {
    if (account === "") return;
    dispatch(updateBalances());
  }, [signature]);

  react.useEffect(() => {
    const interval = setInterval(() => {
      if (account === "") return;
      dispatch(updateBalances());
    }, 45000); // 30000 milliseconds = 30 seconds

    return () => {
      clearInterval(interval);
    };
  }, []);

  react.useEffect(() => {
    const decodedJWT = getCurrentUser();

    setCurrentUser(decodedJWT);
  }, [getCurrentUser]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <AccountContext.Provider value={currentUser}>
          <Navbar
            currentUsername={currentUser && currentUser.username}
            handleConnect={connectAndSign}
          ></Navbar>
          <Content address={account} networkId={currentNetwork}></Content>
          <Footer></Footer>
          <Snackbar
            open={!!error}
            onClose={() => setError("")}
            autoHideDuration={5000}
            message={error}
          ></Snackbar>
        </AccountContext.Provider>
      </ThemeProvider>
    </>
  );
}
