export enum TokenType {
  USDT = "USDT",
  BETNFT = "BETNFT",
}

export interface Race {
  raceId: string;
  raceName: string;
  raceLocation?: string;
  entrants: any[];
  raceDistance: number;
  maxRacers: number;
  completed?: boolean;
  cancelled?: boolean;
  startTime: number;
  raceTimes: number[];
  fee_token: TokenType | null;
  entryFee: string;
  prizePool: string;
  prizeStructure: string[];
  prizePercentages: number[];
  outcome?: any[];
  raceClass: string;
  raceTime?: number;
  raceSpeeds?: number[][];
  raceDists: number[][];
  joinOrder: number[];
  raceCondition: string;
  raceWeather: string;
  maxEntrants?: number;
  sponsorMessage?: string;
  sponsorImage?: string;
  sponsorLink?: string;
  status: string;
  type?: string;
  _id: string;
}

//TODO: Add other attributes
export interface TokenInfo {
  tokenId: number;
  ownerAddress: string;
  name?: string;
  tokenFamily: string; // contract name (LottoMint1, G1-G4 etc...)
  URI?: any; //TODO: make real type
  metadata?: TokenMetadata;
  kennel?: string;
}

export interface TokenMetadata {
  name: string;
  description: string;
  image: string;
  attributes: any[];
  date: string | number;
  dna: string;
  edition: number;
  compiler: string;
  kennelName?: string; //new property
}

export interface User {
  username: string;
  email: string;
  password?: string;
  address?: string;
}
export interface Tournament {
  _id?: any;
  id: string;
  name: string;
  t_class: string;
  startTime: number;
  cutoffTime: number;
  location: string;
  fee_token: TokenType | null;
  entryFee: string;
  prizePool: string;
  prizeStructure: string[];
  prizePercentages: number[];
  totalRounds: number;
  sponsorImage: string;
  sponsorMessage: string;
  sponsorLink: string;
  maxEntrants: number;
  minEntrants: number;
  entrants: any[];
  rawEntrantCount?: number;
  pointStructure: number[]; //point structure of tournament
  completedRaces: string[]; //array of raceIds
  races: string[];
  raceData?: Race[];
  owner: string;
  status: TournamentStatus;
  type: string;
  format: string;
}

export interface TournamentEntrant {
  tokenId: number;
  tokenFamily: string;
  ownerAddress: string;
  kennel: string;
  joinPosition: string;
  points: number;
  metadata?: TokenMetadata;
}

export type TournamentStatus =
  | "waiting"
  | "pending"
  | "confirmed"
  | "cancelled"
  | "canceled"
  | "completed";
