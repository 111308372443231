import { ethers } from "ethers";

export const connectWallet = async (
  currentNetwork: string,
  setAccCb: (addr: string) => void,
  setNetworkCb: (network: string) => void,
  setError: (error: string) => void
) => {
  try {
    const { ethereum } = window as any;
    if (!ethereum) {
      alert("Get MetaMask!");
      return;
    }
    if (currentNetwork !== "0x89") {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: "0x89",
          },
        ],
      });
    }

    const accounts = await ethereum.request({
      method: "eth_requestAccounts",
    });

    setAccCb(accounts[0]);

    setNetworkCb("0x89");
    return accounts[0];
  } catch (error: any) {
    setError(error.message);
  }
};

export const signMessage = async (nonce: string | number) => {
  const { ethereum } = window as any;
  if (ethereum) {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();

    const message = `Sign this to enter BETNFT: ${nonce}`;
    const signature = await signer.signMessage(message);

    // const signature = await ethereum.request({
    //   method: "personal_sign",
    //   params: ["Sign this to enter BETNFT.", accounts[0]],
    //   from: accounts[0],
    // });

    return signature;
  }
};
