import * as react from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import validator from "validator";
import { Race, TokenType } from "../../../types";
import { Routes, Route, Link } from "react-router-dom";
import InfoWhite from "../../assets/info-white.png";
import RaceSummary from "./racesummary";
import {
  formatTokenName,
  getTimeToRace,
  token2DecimalDisplay,
} from "../../../tools/helper";

interface IProps {
  data: any;
  nextToRunData: any;
  isLoading?: boolean;
  isError?: boolean;
}

export default function Resultlist(props: IProps) {
  const { data, nextToRunData } = props;
  const [showSummary, setShowSummary] = react.useState(false);
  const [races, setRaces] = react.useState<Race[]>([]);
  const { uuid } = useParams(); //RaceID
  const [filter, setFilter] = react.useState("");
  const [currentRaceIndex, setCurrentRaceIndex] = react.useState<
    number | undefined
  >(undefined);
  const [hoveredRaces, setHoveredRaces] = react.useState<{
    [key: string]: boolean;
  }>({});

  const handleMouseEnter = (raceId: string) => {
    setHoveredRaces((prevState) => ({ ...prevState, [raceId]: true }));
  };

  const handleMouseLeave = (raceId: string) => {
    setHoveredRaces((prevState) => ({ ...prevState, [raceId]: false }));
  };

  react.useEffect(() => {
    if (uuid) {
      setShowSummary(true);
    } else {
      setShowSummary(false);
    }

    if (data?.data.length > 0 && uuid) {
      const raceIndex = data?.data.findIndex(
        (item: any) => item.raceId === uuid
      );

      if (raceIndex >= 0) {
        setCurrentRaceIndex(raceIndex);
      } else {
        setCurrentRaceIndex(undefined);
      }
    }
  }, [uuid, data]);
  const [timer, setTimer] = react.useState(0);
  react.useEffect(() => {
    //re-render this component every second
    const interval = setInterval(() => {
      setTimer((prev) => prev + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  react.useEffect(() => {
    if (
      nextToRunData &&
      nextToRunData.data.length > 0 &&
      Array.isArray(nextToRunData.data)
    ) {
      //function to sort data by startTime property
      let copy = nextToRunData.data.filter((race: Race) => !race.cancelled);
      const sortData = (copy as Race[]).sort(function (a, b) {
        return a.startTime - b.startTime;
      });

      setRaces(sortData);
    }
  }, [nextToRunData]);
  const handleFilter = (filter: string) => {
    setFilter(filter);
  };
  let count = 0;
  return (
    <>
      <div className="racelist">
        <div className="topbar">
          <span className="sides">Next to Run</span>
          <div className="next">
            {/* TODO: Sort by time left */}
            {races.length > 0 ? (
              races.map((race: Race, index: number) => {
                if (
                  !race.completed ||
                  Date.now() < race.raceTime! * 1000 + race.startTime
                ) {
                  count += 1;
                  let countdown = getTimeToRace(race.startTime);
                  if (race.cancelled) {
                    return;
                  }

                  if (count <= 5)
                    return (
                      <Link
                        to={`/race/${race.raceId}`}
                        className="box"
                        key={race.raceId}
                      >
                        <div className="race-name">{race.raceName}</div>
                        <div className="time">
                          <span className="start">Starts:</span>
                          <span className="clock">
                            {Date.now() < race.startTime
                              ? `${
                                  countdown.hours ? countdown.hours + "h" : ""
                                } ${
                                  countdown.minutes
                                    ? countdown.minutes + "m"
                                    : ""
                                } ${
                                  countdown.seconds
                                    ? countdown.seconds + "s"
                                    : ""
                                }`
                              : "Live"}
                          </span>
                        </div>
                      </Link>
                    );
                }
              })
            ) : (
              <div className="none loading-data">
                {props.isLoading ? "Loading races..." : "No Races Found"}
              </div>
            )}
          </div>
          <span className="sides">See All</span>
        </div>
        <div className="race-classes race-results-classes">
          <div className="title">
            RESULTS<img src={InfoWhite}></img>
          </div>
          <div className="classes">
            <div className="class-filter " onClick={() => handleFilter("")}>
              All
            </div>
            <div
              className="class-filter "
              onClick={() => handleFilter("Chance")}
            >
              Chance
            </div>
            <div className="class-filter " onClick={() => handleFilter("Open")}>
              Open
            </div>
            <div className="class-filter " onClick={() => handleFilter("G1")}>
              Class G1
            </div>
            <div className="class-filter " onClick={() => handleFilter("G2")}>
              Class G2
            </div>
            <div className="class-filter " onClick={() => handleFilter("G3")}>
              Class G3
            </div>
            <div className="class-filter " onClick={() => handleFilter("G4")}>
              Class G4
            </div>
            <div
              style={{ fontSize: "12px" }}
              className="class-filter "
              onClick={() => handleFilter("tournament")}
            >
              Tournament
            </div>
          </div>
        </div>
        <div className="list">
          <div className="titles race-result-titles summary-columns">
            <div>RACE NAME</div>
            <div>Event</div>
            <div>Distance</div>
            <div>Date</div>
            <div>Prize Pool</div>
          </div>

          {/* {showSummary &&
            data &&
            currentRaceIndex != undefined &&
            currentRaceIndex >= 0 && (
              <>
                <div className="race-row  summary-columns">
                  <div className="race-titles ">
                    <span>{data.data[currentRaceIndex].raceName}</span>
                    <span>
                      {data.data[currentRaceIndex].raceLocation || "BetNFT"}
                    </span>
                  </div>
                  <div className="grid-item entry-fee">
                    {data.data.raceClass}
                  </div>

                  <div className="distance grid-item">
                    {data.data[currentRaceIndex].raceDistance}m
                  </div>
                  <div className="entry-fee grid-item">
                    {moment(data.data[currentRaceIndex].startTime).format(
                      "DD MMM, YYYY. hh.mm"
                    )}
                  </div>
                  <div className="prize-pool grid-item">$0 {}</div>
                </div>
                <RaceSummary fromLiveRace={false}></RaceSummary>
              </>
            )} */}
          {!showSummary && (
            <>
              <div className="races race-result-row">
                {Array.isArray(data?.data) ? (
                  data?.data
                    .filter((race: Race) => {
                      if (filter === "tournament") {
                        return race.type?.includes(filter);
                      }
                      if (filter) {
                        return race.raceClass === filter;
                      }
                      return race;
                    })
                    .map((race: Race, index: number) => {
                      const fee_token = race.fee_token
                        ? race.fee_token
                        : TokenType.USDT;
                      const tokenDisplayname = formatTokenName(fee_token);

                      if (Date.now() > 1000 * 40 + race.startTime)
                        return (
                          <Link
                            to={`/race/${race.raceId}?showResult=false`}
                            className="race-row summary-columns"
                            key={race.raceId}
                            onClick={() => {
                              window.scrollTo(0, 0);
                            }}
                          >
                            <div
                              className="race-titles"
                              // onClick={() => handleDetail(index)}
                            >
                              {" "}
                              <span>
                                {race.raceName}
                                {race.cancelled && " - Abandoned"}
                              </span>
                              <span>{race.raceLocation || "BetNFT"}</span>
                            </div>
                            {/* <div className="race-titles"> {race.raceId}</div> */}
                            <div className="grid-item entry-fee">
                              {race.raceClass}
                            </div>

                            <div className="distance grid-item">
                              {race.raceDistance}m
                            </div>
                            <div className="entry-fee grid-item">
                              {moment(race.startTime).format(
                                "DD MMM, YYYY. hh:mm"
                              )}
                            </div>

                            <div
                              onMouseEnter={() => handleMouseEnter(race._id)}
                              onMouseLeave={() => handleMouseLeave(race._id)}
                              className={`prize-pool grid-item relative ${
                                hoveredRaces[race._id] ? "hovered" : ""
                              }`}
                            >
                              {race.prizePool === "0" || !race.prizePool
                                ? "Free"
                                : `${token2DecimalDisplay(
                                    race.prizePool,
                                    fee_token
                                  )} ${tokenDisplayname}`}
                              {hoveredRaces[race._id] && (
                                <div className="custom-tooltip prize-pooltooltip">
                                  <div className="innerdiv">
                                    <span>
                                      Calculated by the number of entrants less
                                      house cost %
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Link>
                        );
                    })
                ) : (
                  <div className="none">
                    {props.isLoading ? "Loading races..." : "No Races Found"}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
