import { Race, TokenType } from "../../../types";
import { useNavigate } from "react-router";
import * as react from "react";
import { ClassIndicator } from "../../contents/races/racelist";
import PrizeTypeBadge from "../PrizeTypeBadge";
import moment from "moment";
import { formatTokenName, token2DecimalDisplay } from "../../../tools/helper";
import { useAppSelector } from "../../../app/hooks";
import { selectAccount } from "../../../store/mainSlice";
import { Check } from "@mui/icons-material";
import { calculatePrizeFromStructure } from "../../../tools/helper";
import { OrdinalMap, TokenDecimals } from "../../utils";

interface IProps {
  race: Race;
}

export default function RaceInfoRow({ race }: IProps) {
  const token = race.fee_token ? race.fee_token : TokenType.USDT;

  let entryFee = token2DecimalDisplay(race.entryFee.toString() || "0", token);
  let prizePool = token2DecimalDisplay(race.prizePool.toString() || "0", token);
  const account = useAppSelector(selectAccount);
  const navigate = useNavigate();
  const [isHovering, setIsHovering] = react.useState("");

  const parentRef = react.useRef<HTMLDivElement | null>(null);
  const hoverBoxRef = react.useRef<HTMLDivElement | null>(null);

  const adjustHoverBoxPosition = () => {
    if (parentRef.current && hoverBoxRef.current) {
      const hoverBoxRect = hoverBoxRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      if (hoverBoxRect.bottom > viewportHeight) {
        // The hover box goes beyond the bottom of the viewport
        const difference = hoverBoxRect.bottom - viewportHeight;
        hoverBoxRef.current.style.top = `${-difference}px`;
      } else {
        // Resetting to default position in case the hover box doesn't overlap with viewport's bottom
        hoverBoxRef.current.style.top = "0px";
      }
    }
  };

  const updateHoverBoxPosition = () => {
    adjustHoverBoxPosition();
  };

  react.useEffect(() => {
    if (isHovering === "PrizePool") {
      // We introduce a slight delay to ensure the hover box is rendered
      // before trying to access its ref.
      updateHoverBoxPosition();
    }
  }, [isHovering]);

  let percentage_array = [...Array(race.maxEntrants || 8).keys()];

  const tokenDisplayName = formatTokenName(token);
  return (
    <div
      className="race-row"
      key={race.raceId}
      onClick={() => {
        window.scrollTo(0, 0);
        navigate(`/race/${race.raceId}`);
      }}
    >
      <div className="race-titles">
        <span>{race.raceName}</span>
        <span>{`"${race.raceLocation}"` || "BetNFT"}</span>
      </div>

      {/* <div className="grid-item">
                          <ClassIndicator>{race.raceClass}</ClassIndicator>
                        </div> */}
      <div className="grid-item event-type-box relative">
        <div
          className="grid-item "
          onMouseOver={() => {
            if (race.raceClass === "Open") {
              setIsHovering("Open");
            }
            if (race.raceClass === "Chance") {
              setIsHovering("Chance");
            }
            if (race.raceClass === "G1") {
              setIsHovering("G1");
            }
            if (race.raceClass === "G2") {
              setIsHovering("G2");
            }
            if (race.raceClass === "G3") {
              setIsHovering("G3");
            }
            if (race.raceClass === "G4") {
              setIsHovering("G4");
            }
          }}
          onMouseOut={() => setIsHovering("")}
        >
          <ClassIndicator>{race.raceClass}</ClassIndicator>
        </div>

        <PrizeTypeBadge
          prizePercentages={race.prizePercentages}
        ></PrizeTypeBadge>
        {isHovering === "Open" ? (
          <div className={`custom-tooltip event-label-tooltip`}>
            <div className="innerdiv">
              <label className="">Any dog can enter this race</label>
            </div>
          </div>
        ) : null}
        {isHovering === "Chance" ? (
          <div className={`custom-tooltip event-label-tooltip `}>
            <div className="innerdiv">
              <label className="">Any dog can enter this race</label>
            </div>
          </div>
        ) : null}
        {isHovering === "G1" ? (
          <div className={`custom-tooltip event-label-tooltip`}>
            <div className="innerdiv">
              <label className="">
                This bloodline only can enter this race
              </label>
            </div>
          </div>
        ) : null}
        {isHovering === "G2" ? (
          <div className={`custom-tooltip event-label-tooltip`}>
            <div className="innerdiv">
              <label className="">
                This bloodline only can enter this race
              </label>
            </div>
          </div>
        ) : null}
        {isHovering === "G3" ? (
          <div className="custom-tooltip event-label-tooltip">
            <div className="innerdiv">
              <label className="">
                This bloodline only can enter this race
              </label>
            </div>
          </div>
        ) : null}
        {isHovering === "G4" ? (
          <div className="custom-tooltip event-label-tooltip">
            <div className="innerdiv">
              <label className="">
                This bloodline only can enter this race
              </label>
            </div>
          </div>
        ) : null}
      </div>
      <div className="distance grid-item">{race.raceDistance}m</div>
      <div className="entry-fee grid-item">
        {moment(race.startTime).format("DD MMM, YYYY. hh.mm a")}
      </div>
      <div className="entry-fee-clor grid-item ">
        {race.entryFee === "0" || !race.entryFee
          ? "Free"
          : `${entryFee} ${tokenDisplayName}`}
      </div>

      <div
        onMouseEnter={() => {
          setIsHovering("PrizePool");
          updateHoverBoxPosition();
        }}
        onMouseLeave={() => setIsHovering("")}
        ref={parentRef}
        className={`prize-pool grid-item relative ${
          isHovering === "PrizePool" ? "hovered" : ""
        }`}
      >
        {prizePool} {tokenDisplayName}
        {isHovering === "PrizePool" && race.prizePercentages.length > 0 && (
          <div
            ref={hoverBoxRef}
            className="custom-tooltip pricepool-tooltip-cover prize-pooltooltip"
          >
            <div className="innerdiv pricepool-tooltip">
              {percentage_array.map((_, index) => {
                if (index === 0) {
                  return (
                    <div className="inner-wrapper">
                      <span className="rank-gold rank">1ST</span>
                      <span>
                        {calculatePrizeFromStructure(
                          race.prizePercentages[index] || 0,
                          race.prizePool,
                          token
                        ).toString()}
                      </span>
                      <span>{tokenDisplayName}</span>
                    </div>
                  );
                }

                if (index === 1) {
                  return (
                    <div className="inner-wrapper">
                      <span className="rank-silver rank">2ND</span>
                      <span>
                        {calculatePrizeFromStructure(
                          race.prizePercentages[index] || 0,
                          race.prizePool,
                          token
                        ).toString()}
                      </span>
                      <span>{tokenDisplayName}</span>
                    </div>
                  );
                }
                if (index === 2) {
                  return (
                    <div className="inner-wrapper">
                      <span className="rank-bronze rank">3RD</span>
                      <span>
                        {calculatePrizeFromStructure(
                          race.prizePercentages[index] || 0,
                          race.prizePool,
                          token
                        ).toString()}
                      </span>
                      <span>{tokenDisplayName}</span>
                    </div>
                  );
                } else {
                  return (
                    <react.Fragment key={index}>
                      <div className="inner-wrapper">
                        <span className="rank">
                          {OrdinalMap[(index + 1) as keyof typeof OrdinalMap]}
                        </span>
                        <span>
                          {calculatePrizeFromStructure(
                            race.prizePercentages[index] || 0,
                            race.prizePool,
                            token
                          ).toString()}
                        </span>
                        <span>{tokenDisplayName}</span>
                      </div>
                    </react.Fragment>
                  );
                }
              })}
            </div>
          </div>
        )}
      </div>

      <div className="registered-racers grid-item">
        {race.entrants
          ? `${race.entrants.length}/${race.maxEntrants || 8}`
          : `0/${race.maxEntrants || 8}`}
        {race.entrants.find((entrant) => entrant.ownerAddress === account) && (
          <>
            <Check></Check>
          </>
        )}
      </div>
    </div>
  );
}
