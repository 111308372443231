import "./style.scss";
import { useHomeContent } from "./../../../services/queries";
import { Button, Container } from "@mui/material";
import { Link } from "react-router-dom";

interface IProps {}

export default function Home(props: IProps) {
  const homeContent = useHomeContent();

  return (
    <>
      <div className="homebg">
        {homeContent.data?.data && homeContent.data.data.homepage.content ? (
          <>
            <a
              className="box-flex"
              style={{
                top: "320px",
                left: "125px",
                transformOrigin: "0 0",
                transform: "rotate(340deg)",
                padding: "10px",
              }}
              // href="https://mint.betnft.run/"
              // target="_blank"
            >
              {homeContent.data.data.homepage.content || ""}
            </a>
          </>
        ) : (
          <></>
        )}
        <Container className="main-box">
          <div className="boxes-container">
            <a
              className="box"
              style={{ top: "190px" }}
              href="https://mint.betnft.run/"
              target="_blank"
            >
              Buy a &nbsp;<span className="dark-green">Greyhound</span>
            </a>
            <Link className="box " style={{ top: "290px" }} to="/race">
              Start &nbsp;<span className="dark-green">Racing!</span>
            </Link>
            {/* <a className="box " style={{ top: "290px" }} href="/race">
              Start &nbsp;<span className="dark-green">Racing!</span>
            </a> */}
          </div>
        </Container>
        <div className="welcome">
          <div className="greet-msg">
            <span className="dark-green">Welcome</span> to the{" "}
            <span className="dark-green">world</span> of{" "}
            <span className="dark-green">NFT</span> racing!{" "}
            {/* <img src={Arrow} className="arrow"></img> */}
          </div>
          <div className="community">Join our community & stay up to date</div>
          <div className="email-ref">
            <div>
              <Button
                onClick={() =>
                  window.open(
                    "https://9dbae709.sibforms.com/serve/MUIEACnzNh3GPZVfTAkI4BOb-c8ebhtoLW-cOmdzdlWfeo_hyToeRA67rnXPAUFZkUnqcZL3_cXLCbn2i08AHVxw604zYTPicM2_Wlu2eF9DXq7VHYsqFfxzaVsqjqkzJNGbbKFXA7bxVBvjkEjBYTJ_4BrR7rnf0f-2R6ZCeGtM-x-Iw8IoJZl0aiqUIa1cq1212dPQvBc0oHIg",
                    "_blank"
                  )
                }
                className="connect-btn"
              >
                Join Here
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
