import * as react from "react";
import "./naming.scss";
import { TokenInfo } from "../../../types";
import { getTokenImage } from "../../../services/queries";
import LogoWShadow from "../../assets/logowshadow.png";
import { UseMutationResult } from "@tanstack/react-query";
interface IProps {
  handleSubmitName: (
    tokenFamily: string,
    tokenId: number,
    name: string
  ) => void;
  handleClose: () => void;
  tokenData: TokenInfo;
  children?: react.ReactNode;
  error?: string;
  mutation: UseMutationResult<any>;
}

export default function NameToken(props: IProps) {
  const [inputValue, setInputValue] = react.useState("");
  return (
    <>
      <div className="bg">
        <div className="close" onClick={() => props.handleClose()}>
          X
        </div>
        <div className="container">
          <div className="title">Name Your Greyhound</div>
          <img
            src={getTokenImage(props.tokenData.metadata?.image!)}
            alt="token"
            className="token"
          />
          <div className="input-wrapper">
            <label htmlFor="dog-name" className="input-label">
              Greyhound Name *
            </label>
            <input
              type="text"
              name="dog-name"
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>
          <div className="conditional">
            * Inappropriate names will not be accepted
          </div>
          <div className="extra-notes">
            <div className="point">
              😊 Be cool and choose a name that's creative and fun.
            </div>
            <div className="point">
              ☹️ Don't be cool and have your name rejected and removed.
            </div>
            <div className="note">
              *Please note: Names already used will not be saved, you'll be
              asked to enter a new name. Once a name has been accepted, it
              cannot be changed again.
            </div>
          </div>
          <button
            className="save"
            onClick={() =>
              props.handleSubmitName(
                props.tokenData.tokenFamily,
                props.tokenData.tokenId,
                inputValue
              )
            }
          >
            Save
          </button>
          <div className="error">{props.error} </div>
        </div>
        <img src={LogoWShadow} alt="logo" className="kennel-logo" />
      </div>
    </>
  );
}
