import "./style.scss";
import * as react from "react";
import Background from "../../common/Background";
import TempImg from "./temp.png";
interface Props {
  children?: react.ReactNode;
}

export default function Marketplace(props: Props) {
  return (
    <Background>
      <div className="market-temp"></div>
    </Background>
  );
}
