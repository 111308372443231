import * as react from "react";
import React, { useState } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { Box, Menu, MenuItem, Switch } from "@mui/material";
import downArrow from "../../../assets/down-arrow-green.svg";
import { HighlightOff } from "@mui/icons-material";
import { useAppSelector } from "../../../../app/hooks";
import { selectAccount } from "../../../../store/mainSlice";
import { CircularProgress } from "@mui/material";
import {
  useGetKennelLeaderboard,
  useGetTokenLeaderboard,
} from "../../../../services/queries";
import { token2DecimalDisplay } from "../../../../tools/helper";
import { TokenType } from "../../../../types";

const data = [{ name: "Greyhounds" }, { name: "Kennels" }];

const bloodline = [
  { name: "G1", color: "#2a9d8f" },
  { name: "G2", color: "#bc6c25" },
  { name: "G3", color: "#669bbc" },
  { name: "G4", color: "#004e89" },
];

const LeaderBoards = () => {
  const [val, setval] = useState("Greyhounds");
  const [raceEnter, setRaceEnter] = react.useState<null | HTMLElement>(null); // main race to enter menu
  let account = useAppSelector(selectAccount);
  const [showAll, setShowAll] = useState(true);
  const [greyhoundFilter, setGreyhoundFilter] = useState("All");

  const openRaceEnter = Boolean(raceEnter);

  const kennelLeaderboard = useGetKennelLeaderboard();
  const tokenLeaderboard = useGetTokenLeaderboard(
    greyhoundFilter,
    showAll ? "" : account
  );

  const { data: kennelData, isLoading: kennelLoading } = kennelLeaderboard;
  const { data: tokenData, isLoading: tokenLoading } = tokenLeaderboard;

  return (
    <>
      <div className="tab-main">
        <div className="tab-sec">
          {data.map((menu, i) => {
            return (
              <button
                className={`tab-btn ${val === menu.name && "active"}`}
                key={i}
                onClick={() => setval(menu.name)}
              >
                {menu.name}
              </button>
            );
          })}
          {val === "Greyhounds" && (
            <>
              <button className="tab-btn">
                Bloodline
                <img
                  src={downArrow}
                  className="down-arrow"
                  id="race-enter"
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    setRaceEnter(event.currentTarget)
                  }
                ></img>
                <Box
                  className="filter-btn"
                  sx={{
                    marginLeft: "15px",
                    background: "#2a9d8f",
                    cursor: "default",
                  }}
                >
                  {greyhoundFilter}
                </Box>
                {greyhoundFilter !== "All" && (
                  <Box
                    className="filter-btn cancel-btn"
                    onClick={() => setGreyhoundFilter("All")}
                  >
                    Cancel
                    <HighlightOff
                      sx={{ marginLeft: "5px", fontSize: "17px" }}
                    />
                  </Box>
                )}
              </button>
              {account && (
                <>
                  <button className="tab-btn " style={{ marginLeft: "auto" }}>
                    {showAll ? "Show My Dogs" : "Show All Dogs"}
                    <Switch
                      value={showAll}
                      onClick={() => {
                        console.log("show all", showAll);
                        setShowAll(!showAll);
                      }}
                    ></Switch>
                  </button>
                </>
              )}
              <div>
                <Menu
                  id="race-enter-menu"
                  className="race-enter"
                  anchorEl={raceEnter}
                  open={openRaceEnter}
                  onClose={() => {
                    setRaceEnter(null);
                  }}
                  MenuListProps={{
                    "aria-labelledby": "race-enter",
                  }}
                >
                  {bloodline?.map((val, index) => {
                    return (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          setGreyhoundFilter(val.name);
                          setRaceEnter(null);
                        }}
                        sx={{
                          backgroundColor: val.color,
                          margin: "7px",
                          padding: "5px 20px",
                          borderRadius: "5px",
                          ":hover": { backgroundColor: val.color },
                        }}
                        id="gates"
                      >
                        {val.name}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </div>
            </>
          )}
        </div>
      </div>
      <div>
        <div className="list">
          <div className="titles race-result-titles summary-columns">
            <div>NAME</div>
            <div>NO. OF RACES</div>
            <div>WINS</div>
            <div>PLACES</div>
            <div>PRIZE MONEY (USDT)</div>
          </div>
          <div className="races race-result-row">
            {val === "Greyhounds" && tokenLoading && (
              <>
                <div style={{ display: "flex" }}>
                  <CircularProgress
                    style={{ margin: "auto" }}
                  ></CircularProgress>
                </div>
              </>
            )}
            {val === "Greyhounds" &&
              tokenData?.data.map((val: any, index: number) => {
                let places = val.career.seconds + val.career.thirds;
                let tokenType = TokenType.USDT;
                let prize_money = token2DecimalDisplay(
                  val.total_winnings,
                  tokenType
                );
                return (
                  <div
                    //to="/race/leaderboard"
                    key={index}
                    className="race-row summary-columns"
                  >
                    <div className="race-titles">
                      <span>
                        <span style={{ color: "yellow" }}>{index + 1})</span>{" "}
                        {val.name}
                      </span>
                    </div>
                    <div className="grid-item entry-fee">
                      {val.completed_races}
                    </div>
                    <div className="distance grid-item">
                      {val.career.firsts}
                    </div>
                    <div className="grid-item grid-places">{places}</div>
                    <div className="prize-pool grid-item relative ">
                      {prize_money}
                    </div>
                  </div>
                );
              })}
            {val === "Kennels" && kennelLoading && (
              <>
                <div style={{ display: "flex" }}>
                  <CircularProgress
                    style={{ margin: "auto" }}
                  ></CircularProgress>
                </div>
              </>
            )}
            {val === "Kennels" &&
              kennelData?.data.USDT.map((val: any, index: number) => {
                let places = val.career.seconds + val.career.thirds;

                let prize_money = token2DecimalDisplay(
                  val.total_winnings,
                  TokenType.USDT
                );

                return (
                  <Link
                    to="/race/leaderboard"
                    key={index}
                    className="race-row summary-columns"
                  >
                    <div className="race-titles">
                      <span>
                        <span style={{ color: "yellow" }}>{index + 1})</span>{" "}
                        {val.name}
                      </span>
                    </div>
                    <div className="grid-item entry-fee">
                      {val.completed_races}
                    </div>
                    <div className="distance grid-item">
                      {val.career.firsts}
                    </div>
                    <div className="grid-item grid-places">{places}</div>
                    <div className="prize-pool grid-item relative ">
                      {prize_money}
                    </div>
                  </Link>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaderBoards;
