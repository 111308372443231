import "./style.scss";
import { ethers } from "ethers";
import * as react from "react";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import Switch from "@mui/material/Switch";
import {
  useTournament,
  useCurrentTournament,
  useJoinTournament,
  useTournamentRacesQuery,
} from "../../../../services/queries";
import Popup from "../../../common/Popup";
import JoinTournament from "./joinpopup";
import moment from "moment";
import { Button } from "@mui/material";
import { Routes, Route, Link, useParams, useNavigate } from "react-router-dom";
import InfoWhite from "../../../assets/info-white.png";
import { selectSignature, selectAccount } from "../../../../store/mainSlice";
import { updateBalances } from "../../../../store/accountSlice";
import {
  Tournament,
  TournamentEntrant,
  Race,
  TournamentStatus,
  TokenType,
} from "../../../../types";
import {
  calculatePrizeStructure,
  formatTokenAmount,
  formatTokenName,
  formatTournamentFormat,
  token2DecimalDisplay,
} from "../../../../tools/helper";
import { getTokenMetadata, getTokenImage } from "../../../../services/queries";
import PlaceholderDog from "../../../assets/placeholderdog.png";
import { trimBloodlineAttribute } from "../../kennel";
import { getFormatDescription, getPrizeDistribution } from "./utils";
import { TokenDecimals } from "../../../utils";

export function TournamentDetail() {
  const { tournamentId } = useParams();
  const dispatch = useAppDispatch();
  const signature = useAppSelector(selectSignature);
  const account = useAppSelector(selectAccount);
  const join = useJoinTournament();
  const [loading, setLoading] = react.useState(false);
  // change between Nominees and Status
  const [error, setError] = react.useState("");
  const [hoveredRaces, setHoveredRaces] = react.useState<{
    [key: string]: boolean;
  }>({});

  const [currentPanel, setCurrentPanel] = react.useState(0);
  const [showJoinPopup, setShowJoinPopup] = react.useState(false);
  const [showAll, setShowAll] = react.useState(true);
  const { data: res, isLoading, refetch } = useCurrentTournament(tournamentId!);
  const { data: t } = res ? (res as { data: Tournament }) : { data: null };
  const navigate = useNavigate();

  const tournamentRaces =
    t && t.raceData
      ? t.raceData.sort((a, b) => {
          return a.raceName > b.raceName ? 1 : -1;
        })
      : [];

  // const tournamentRaces = useTournamentRacesQuery(t ? t.races : []);
  // const t_races = tournamentRaces.map((queryResponse, index) => {
  //   const { data } = queryResponse;
  //   console.log("data for tourn races", data);
  // });

  const updateTokenInfo = react.useCallback(async () => {
    if (!t) return;
    setLoading(true);
    let raceTokensMetadata = await Promise.all(
      t.entrants.map((e) => {
        let token = e;
        return getTokenMetadata(token.tokenId, token.tokenFamily);
      })
    );

    for (let i = 0; i < t.entrants.length; i++) {
      let token = t.entrants[i];
      token.metadata = raceTokensMetadata[i];
    }
    setLoading(false);
  }, [t]);

  react.useEffect(() => {
    updateTokenInfo();
  }, [updateTokenInfo]);

  const handleJoin = async (tokenFamily: string, tokenId: number) => {
    if (!signature) {
      alert("Please sign in first");
      return;
    }
    if (!account) {
      alert("Please connect wallet first");
      return;
    }
    try {
      let success = await join.mutateAsync({
        tokenId: tokenId,
        tokenFamily: tokenFamily,
        ownerAddress: account,
        tournamentId: tournamentId,
        signature: signature,
      });
      dispatch(updateBalances());
      console.log("Success join tournament!", success);
      refetch();
    } catch (e: any) {
      console.log("Join tournament error", e);
      setError(e.response.data);
    }
  };

  const resetMutation = () => {
    join.reset();
  };

  const closePopup = () => {
    setShowJoinPopup(false);
  };

  let startTimeMessage =
    t?.format === "sitngo"
      ? "When Filled"
      : moment(t?.cutoffTime).format("DD MMM, YYYY. hh.mm a");

  const fee_token = t?.fee_token ? t.fee_token : TokenType.USDT;
  const tokenDisplayName = formatTokenName(fee_token);
  const tokenDecimals = TokenDecimals[fee_token];

  const getPrizePoolMessage = () => {
    if (!t) return "";
    let tournamentFormat = t.format;
    let currentEntrants =
      tournamentFormat === "unlimited"
        ? t.status === "cancelled" || t.status === "waiting"
          ? 0
          : t.rawEntrantCount
        : t.maxEntrants;
    if (!currentEntrants) {
      return "TBD";
    }
    let prizePool = t.prizePool;

    return `$${formatTokenAmount(
      prizePool,
      tokenDecimals
    )} ${tokenDisplayName}`;
  };

  const handleMouseEnter = (raceId: string) => {
    setHoveredRaces((prevState) => ({ ...prevState, [raceId]: true }));
  };

  const handleMouseLeave = (raceId: string) => {
    setHoveredRaces((prevState) => ({ ...prevState, [raceId]: false }));
  };

  return (
    <>
      {showJoinPopup && t && (
        <Popup>
          <JoinTournament
            handleJoin={handleJoin}
            handleClose={closePopup}
            handleReset={resetMutation}
            tData={t}
            joinStatus={join}
            error={error}
          ></JoinTournament>
        </Popup>
      )}
      <div className="racelist">
        <div className="topbar">
          <span className="sides">
            <button className="prev-btn sides-btn" onClick={() => navigate(-1)}>
              <img src="/left-arrow.svg" className="left-arrow" /> Back
            </button>
          </span>
          <div className="tournaments-title">
            <span className="heading-tournament">Tournaments</span>
            <div className="title-info">
              {t ? (
                <>
                  <span>
                    This tournament is a{" "}
                    {t.type ? `"${t.type}"` : "First to Line"} points system.
                    1st-{t.pointStructure[0]}pts, 2nd-{t.pointStructure[1]}pts,
                    3rd-{t.pointStructure[2]}pts, 4th-{t.pointStructure[3]}pts,
                    5th-{t.pointStructure[4]}pts, 6th-{t.pointStructure[5]}pts,
                    7th-{t.pointStructure[6]}pts, 8th-{t.pointStructure[7]}pts
                  </span>
                  <span>
                    The dogs with the top 8 highest points over the qualifying
                    races proceed through to the Grand Final where the winners
                    will be declared.
                  </span>
                  <span style={{ marginTop: "10px" }}>
                    {getFormatDescription(t.format)}
                  </span>
                  <span>{getPrizeDistribution(t.prizePercentages || [])}</span>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <span></span>
          {/* <span className="sides">See All</span> */}
        </div>
        <div className="race-classes tournament-detail-sections">
          {(t?.status === "waiting" || t?.status === "pending") && (
            <>
              <Button
                sx={{ fontSize: "20px" }}
                variant="contained"
                onClick={() => {
                  setShowJoinPopup(true);
                }}
              >
                Join
              </Button>
            </>
          )}

          <div
            className={`title panel-switch ${
              currentPanel === 0 && "panel-switch-active "
            }`}
            //style={{ marginLeft: "250px" }}
            onClick={() => setCurrentPanel(0)}
          >
            NOMINEES <img src={InfoWhite} alt=""></img>
          </div>
          <div
            className={`title panel-switch ${
              currentPanel === 1 && "panel-switch-active "
            }`}
            //style={{ marginLeft: "250px" }}
            onClick={() => setCurrentPanel(1)}
          >
            STATUS <img src={InfoWhite} alt=""></img>
          </div>
          <div className="show-switch">
            <span>{!showAll ? "Show My Dogs" : "Show All Dogs"}</span>
            <Switch onChange={() => setShowAll(!showAll)}></Switch>
          </div>
        </div>
        <div className={`list ${currentPanel === 0 ? "race-main-list" : ""} `}>
          <div className="titles tournament-titles">
            <div>Tournament Name</div>
            <div>Event Type</div>
            <div>Distance</div>
            <div>Start Date</div>
            <div>Entry Fee</div>
            <div>Prize Pool</div>
            <div>Status</div>
          </div>
          {t ? (
            <>
              {currentPanel === 0 ? (
                <>
                  <div className="races" style={{ minHeight: "auto" }}>
                    <div className="tour-race-row-main">
                      <div className="race-row tour-race-row">
                        <div className="race-titles">
                          <span>{t.name}</span>
                          <span>{t.location || "BetNFT"}</span>
                          {/* <span>{t.location || "BetNFT"}</span> */}
                        </div>
                        <div className="grid-item entry-fee row-class-col race-titles">
                          {t.t_class}

                          <span className="event-green">
                            {formatTournamentFormat(t.format)}
                          </span>
                          <span className="event-white">{t.type}</span>
                        </div>
                        <div className="distance grid-item ">
                          400m, 500m, 600m, 700m, Grand Final
                        </div>
                        <div className="entry-fee grid-item">
                          {startTimeMessage}
                        </div>
                        <div className=" grid-item ">
                          {t.entryFee === "0" || !t.entryFee
                            ? "Free"
                            : `$${formatTokenAmount(
                                t.entryFee,
                                tokenDecimals
                              )} ${tokenDisplayName}`}
                        </div>
                        {/* <div className="prize-pool grid-item">
                          {getPrizePoolMessage()}
                        </div> */}
                        <div
                          onMouseEnter={() => handleMouseEnter(t._id)}
                          onMouseLeave={() => handleMouseLeave(t._id)}
                          className={`prize-pool grid-item relative ${
                            hoveredRaces[t._id] ? "hovered" : ""
                          }`}
                        >
                          {getPrizePoolMessage()}
                          {hoveredRaces[t._id] && (
                            <div className="custom-tooltip prize-pooltooltip">
                              <div className="innerdiv">
                                <span>
                                  Calculated by the number of entrants less
                                  house cost %
                                </span>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="registered-racers grid-item status_wait">
                          {(t.status === "waiting" || t.status === "pending") &&
                          t.format === "unlimited"
                            ? "ENTER"
                            : t.status}
                          {t.format === "sitngo" && t.status === "waiting" && (
                            <>
                              {" "}
                              {t.entrants.length}/{t.maxEntrants}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="races races-seco">
                    {t.entrants
                      .sort((a, b) => {
                        return b.points - a.points;
                      })
                      .map((e: TournamentEntrant, index) => {
                        let bloodlineTag, bloodlineName;
                        if (e.metadata) {
                          let token = e.metadata;

                          let bloodlineAttrIndex = token.attributes.findIndex(
                            (attr: any) =>
                              attr.trait_type === "Bloodline" ||
                              attr.trait_type === "bloodline"
                          );
                          let [_1, _2] = trimBloodlineAttribute(
                            token.attributes[bloodlineAttrIndex!].value
                          );
                          bloodlineName = _2;
                          bloodlineTag = _1;
                        }
                        if (!showAll && e.ownerAddress !== account) return;

                        return (
                          <>
                            <div
                              className="position-row points-row"
                              key={index + "row"}
                            >
                              <div className="position-index">
                                <span>{index + 1}</span>
                              </div>
                              <div className="position-info">
                                <div className="img-container">
                                  <img
                                    src={
                                      e.metadata
                                        ? getTokenImage(e.metadata.image)
                                        : PlaceholderDog
                                    }
                                    alt="dawg"
                                  />
                                </div>
                                <div className="detail">
                                  <div className="top ">
                                    <div></div>
                                    <div className="top-1">
                                      <span className="name">
                                        {e.metadata?.name || e.tokenId}
                                      </span>
                                    </div>
                                    <div className="top-2">
                                      <span className="extra">Points:</span>
                                      <span className="time">{e.points}</span>
                                    </div>
                                  </div>
                                  <div className="bottom ">
                                    <div></div>
                                    <div className="bot-1">
                                      <span className="bloodline">
                                        {bloodlineTag} - {bloodlineName}
                                      </span>
                                    </div>
                                    <div className="bot-2">
                                      <span className="bloodline">
                                        {e.kennel}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </>
              ) : (
                <>
                  <div className="races">
                    {tournamentRaces ? (
                      tournamentRaces.map((r, index) => {
                        if (
                          !showAll &&
                          r.entrants.find((e) => {
                            return e.ownerAddress !== account;
                          })
                        )
                          return;
                        let entryFee = ethers.utils.formatUnits(
                          t.entryFee ? t.entryFee : 0,
                          6
                        );
                        return (
                          <Link
                            className="race-row"
                            key={index + "race-row"}
                            to={`/race/${r.raceId}`}
                          >
                            <div className="race-titles">
                              <span>{t.name}</span>
                              <span>{r.raceLocation}</span>
                            </div>
                            <div className="grid-item entry-fee row-class-col ">
                              <span>{t.t_class}</span> - <span>{t.type}</span>
                            </div>
                            <div className="distance grid-item ">
                              {r.raceDistance}m
                            </div>
                            <div className="entry-fee grid-item">
                              {moment(r.startTime).format(
                                "DD MMM, YYYY. hh.mm a"
                              )}
                            </div>
                            <div className="entry-fee grid-item">
                              {t.entryFee === "0" || !t.entryFee
                                ? "Free"
                                : `$${formatTokenAmount(
                                    t.entryFee,
                                    tokenDecimals
                                  )} ${tokenDisplayName}`}
                            </div>
                            <div className="prize-pool grid-item">
                              {getPrizePoolMessage()}
                            </div>
                            <div className="registered-racers grid-item race-status">
                              {r.type !== "tournament-final" && (
                                <span>
                                  Race {index + 1}/{tournamentRaces.length - 1}
                                </span>
                              )}
                              {r.type === "tournament-final" && (
                                <>
                                  <span>Grand Final</span>
                                </>
                              )}

                              <span>
                                {r.completed && "Completed"}
                                {r.cancelled && "Cancelled"}
                                {r.startTime < Date.now() &&
                                  !r.completed &&
                                  !r.cancelled &&
                                  "Running"}
                                {!r.completed &&
                                  !r.cancelled &&
                                  r.startTime > Date.now() &&
                                  "Upcoming"}
                              </span>
                            </div>
                          </Link>
                        );
                      })
                    ) : (
                      <>No Race Data</>
                    )}
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export function TournamentList() {
  const account = useAppSelector(selectAccount);
  const [showCompleted, setShowCompleted] = react.useState(false);
  const [hoveredRaceId, setHoveredRaceId] = react.useState("");
  const { data: tournamentList, isLoading } = useTournament();
  const [showAll, setShowAll] = react.useState(true);
  const [filter, setFilter] = react.useState("");
  const navigate = useNavigate();

  const [tournamentData, setTournamentData] = react.useState<Tournament[]>([]);
  react.useEffect(() => {
    if (tournamentList?.data) {
      let sorted = tournamentList.data.sort((a: Tournament, b: Tournament) => {
        //if the tournament has not started yet, sort by cutoffTime
        if (a.cutoffTime > Date.now() && b.cutoffTime > Date.now()) {
          return a.cutoffTime > b.cutoffTime ? 1 : -1;
        }
        return b.cutoffTime > a.cutoffTime ? 1 : -1;
      });
      setTournamentData(sorted);
    }
  }, [tournamentList]);

  const [hoveredFields, setHoveredFields] = react.useState(
    Array(tournamentData.length).fill(false)
  );

  const handleFilter = (filter: string) => {
    setFilter(filter);
  };

  const getPrizePoolMessage = (t: Tournament) => {
    const fee_token = t?.fee_token ? t.fee_token : TokenType.USDT;
    const tokenDisplayName = formatTokenName(fee_token);
    const tokenDecimals = TokenDecimals[fee_token];
    let tournamentFormat = t.format;
    let currentEntrants =
      tournamentFormat === "unlimited"
        ? t.status === "cancelled" || t.status === "waiting"
          ? 0
          : t.rawEntrantCount
        : t.maxEntrants;
    if (!currentEntrants) {
      return "TBD";
    }
    let prizePool = t.prizePool;

    return `$${formatTokenAmount(
      prizePool,
      tokenDecimals
    )} ${tokenDisplayName}`;
  };

  const toggleCompleted = () => {
    setShowCompleted(!showCompleted);
  };

  const handleMouseEnter = (raceId: string) => {
    setHoveredRaceId(raceId);
  };

  const handleMouseLeave = () => {
    setHoveredRaceId("");
  };

  return (
    <>
      <div className="racelist">
        <div className="topbar">
          <span className="sides ">
            <button className="prev-btn sides-btn" onClick={() => navigate(-1)}>
              <img src="/left-arrow.svg" className="left-arrow" /> Back
            </button>
          </span>
          <div className="tournaments-title">
            <span className="heading-tournament">Tournaments</span>
            <div className="title-info">
              <span>
                Tournaments can have different entry conditions and point
                systems which determine the outcomes.
              </span>
              <span> Please do your homework and choose accordingly.</span>
            </div>
          </div>
          <span className="sides"></span>
        </div>
        <div className="race-classes race-tournament-classes">
          <div className="title">
            ENTER A TOURNAMENT <img src={InfoWhite} alt=""></img>
          </div>
          <div className="classes">
            <div className="class-filter " onClick={() => handleFilter("")}>
              All
            </div>
            <div
              className="class-filter "
              onClick={() => handleFilter("Chance")}
            >
              Chance
            </div>
            <div className="class-filter " onClick={() => handleFilter("Open")}>
              Open
            </div>
            <div className="class-filter " onClick={() => handleFilter("G1")}>
              G1
            </div>
            <div className="class-filter " onClick={() => handleFilter("G2")}>
              G2
            </div>
            <div className="class-filter " onClick={() => handleFilter("G3")}>
              G3
            </div>
            <div className="class-filter " onClick={() => handleFilter("G4")}>
              G4
            </div>
          </div>
          <Button
            className="btn-hover"
            style={{ marginLeft: "24px", color: "yellow", fontSize: "19px" }}
            onClick={() => toggleCompleted()}
          >
            {showCompleted ? "Live" : "Completed"}
          </Button>
          {/* <div className="show-switch" style={{ marginLeft: "auto" }}>
            <span>{showAll ? "Show My Dogs" : "Show All Dogs"}</span>
            <Switch onChange={() => setShowAll(!showAll)}></Switch>
          </div> */}
        </div>
        <div className="list">
          <div className="titles tournament-titles">
            <div>Tournament Name</div>
            <div>Event Type</div>
            <div>Distance</div>
            <div>Start Date</div>
            <div>Entry Fee</div>
            <div>Prize Pool</div>
            <div>Status</div>
          </div>
          <div className="races">
            {tournamentList ? (
              tournamentData.map((t: Tournament, index: number) => {
                if (!showCompleted && t.status === "completed") return;
                if (showCompleted && t.status !== "completed") return;
                if (t.status === "cancelled" || t.status === "canceled") return;
                if (
                  !showAll &&
                  !t.entrants.find((e) => e.ownerAddress === account)
                )
                  return;
                if (filter && t.t_class !== filter) return;
                const fee_token = t?.fee_token ? t.fee_token : TokenType.USDT;
                const tokenDisplayName = formatTokenName(fee_token);
                let startTimeMessage =
                  t.format === "sitngo"
                    ? "When Filled"
                    : moment(t.cutoffTime).format("DD MMM, YYYY. hh.mm a");

                return (
                  <Link
                    to={`/race/tournaments/${t.id}`}
                    className="race-row"
                    key={t.id}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div className="race-titles">
                      <span>{t.name}</span>
                      <span>{t.location}</span>
                      {/* <span>{`"${t.type}"` || "BetNFT"}</span> */}
                    </div>

                    <div className="grid-item entry-fee row-class-col race-titles relative">
                      <span
                        onMouseEnter={() => {
                          const updatedHoveredFields = [...hoveredFields];
                          updatedHoveredFields[index] = 1;
                          setHoveredFields(updatedHoveredFields);
                        }}
                        onMouseLeave={() => {
                          const updatedHoveredFields = [...hoveredFields];
                          updatedHoveredFields[index] = 0;
                          setHoveredFields(updatedHoveredFields);
                        }}
                      >
                        {t.t_class}
                      </span>
                      <span
                        onMouseEnter={() => {
                          const updatedHoveredFields = [...hoveredFields];
                          updatedHoveredFields[index] = 2;
                          setHoveredFields(updatedHoveredFields);
                        }}
                        onMouseLeave={() => {
                          const updatedHoveredFields = [...hoveredFields];
                          updatedHoveredFields[index] = 0;
                          setHoveredFields(updatedHoveredFields);
                        }}
                        className="event-green"
                      >
                        {formatTournamentFormat(t.format)}
                      </span>
                      <span
                        onMouseEnter={() => {
                          const updatedHoveredFields = [...hoveredFields];
                          updatedHoveredFields[index] = 3;
                          setHoveredFields(updatedHoveredFields);
                        }}
                        onMouseLeave={() => {
                          const updatedHoveredFields = [...hoveredFields];
                          updatedHoveredFields[index] = 0;
                          setHoveredFields(updatedHoveredFields);
                        }}
                        className="event-white"
                      >
                        {t.type}
                      </span>

                      {hoveredFields[index] === 1 && (
                        <div className="custom-tooltip firsthover">
                          <div className="innerdiv">
                            <span>Bloodline</span>
                          </div>
                        </div>
                      )}

                      {hoveredFields[index] === 2 && (
                        <div className="custom-tooltip">
                          <div className="innerdiv">
                            <span>Tournament Format</span>
                          </div>
                        </div>
                      )}

                      {hoveredFields[index] === 3 && (
                        <div className="custom-tooltip three-tooltip">
                          <div className="innerdiv">
                            <span>Points System</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="distance grid-item ">
                      400m, 500m, 600m, 700m, Grand Final
                    </div>
                    <div className="entry-fee grid-item">
                      {startTimeMessage}
                    </div>
                    <div className="entry-fee-clor grid-item">
                      {t.entryFee === "0" || !t.entryFee
                        ? "Free"
                        : `${token2DecimalDisplay(
                            t.entryFee,
                            fee_token
                          )} ${tokenDisplayName}`}
                    </div>
                    {/* <div className="prize-pool grid-item">
                      {getPrizePoolMessage(t)}
                    </div> */}
                    <div
                      onMouseEnter={() => handleMouseEnter(t._id)}
                      onMouseLeave={handleMouseLeave}
                      className={`prize-pool grid-item relative ${
                        hoveredRaceId === t._id ? "hovered" : ""
                      }`}
                    >
                      {getPrizePoolMessage(t)}
                      {hoveredRaceId === t._id && (
                        <div className="custom-tooltip prize-pooltooltip">
                          <div className="innerdiv">
                            <span>
                              Calculated by the number of entrants less house
                              cost %
                            </span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="registered-racers grid-item status-clr">
                      {(t.status === "waiting" || t.status === "pending") &&
                      t.format === "unlimited"
                        ? "ENTER"
                        : t.status}
                      {t.format === "sitngo" && t.status === "waiting" && (
                        <>
                          {" "}
                          {t.entrants.length}/{t.maxEntrants}
                        </>
                      )}
                    </div>

                    {/* {hoveredFields[index] === 1 && (
                      <div className="hover-box">
                        <span>Hover Box Content for Field 1</span>
                      </div>
                    )}

                    {hoveredFields[index] === 2 && (
                      <div className="hover-box">
                        <span>Hover Box Content for Field 2</span>
                      </div>
                    )}

                    {hoveredFields[index] === 3 && (
                      <div className="hover-box">
                        <span>Hover Box Content for Field 3</span>
                      </div>
                    )} */}
                  </Link>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
